import React from "react";

import { Modal, Button, Form } from "react-bootstrap";

class InfoEditor extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      show: true,
      location: props.rowData.location,
      special: props.rowData.special
    };

    // create ref so we can set focus to the first control
    this.location = React.createRef();
    this.button = React.createRef();

    this.handleClose = this.handleClose.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
  }

  getValue() {
    return { location: this.state.location, special: this.state.special };
  }

  getInputNode() {}

  disableContainerStyles() {
    return false;
  }

  handleClose() {
    this.setState({ show: false }, () => this.props.onCommit());
  }

  handleKeyDown(event) {
    if (event.key === "Tab") {
      if (document.activeElement === this.button.current && !event.shiftKey) {
        this.location.current.focus();
        event.preventDefault();
      } else if (
        document.activeElement === this.location.current &&
        event.shiftKey
      ) {
        this.button.current.focus();
        event.preventDefault();
      }
      event.stopPropagation();
    }
  }

  handleChange(event) {
    const fieldValue = event.target.value;
    const fieldName = event.target.id || event.target.name;

    this.setState({ [fieldName]: fieldValue });
  }

  render() {
    return (
      <Form>
        <Modal
          show={this.state.show}
          onHide={this.handleClose}
          onKeyDown={this.handleKeyDown}
          size="sm"
        >
          <Modal.Header>
            <Modal.Title id="contained-modal-title-lg">
              Extra Information
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group controlId="location">
              <Form.Label>Location</Form.Label>
              <Form.Control
                as="textarea"
                placeholder="Where the shades go"
                value={this.state.location}
                ref={this.location}
                onChange={this.handleChange}
              />
              <Form.Text className="text-muted">Where the shades go</Form.Text>
            </Form.Group>
            <Form.Group controlId="special">
              <Form.Label>Special Instructions</Form.Label>
              <Form.Control
                as="textarea"
                placeholder="How to make them"
                value={this.state.special}
                onChange={this.handleChange}
              />
              <Form.Text className="text-muted">
                Anything we should know?
              </Form.Text>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.handleClose} ref={this.button}>
              Done
            </Button>
          </Modal.Footer>
        </Modal>
      </Form>
    );
  }
}

export default InfoEditor;
