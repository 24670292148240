const quote = (
  state = {
    data: [],
    error: null,
    loading: true
  },
  action
) => {
  switch (action.type) {
    case "QUOTES_LOADING":
      return { ...state, loading: true };
    case "QUOTES_RECEIVED":
      return { ...state, data: action.data, error: null, loading: false };
    case "QUOTES_CANCEL":
      return { ...state, data: action.data, error: null };
    case "QUOTES_ERROR":
      return { ...state, data: [], error: action.error, loading: false };
    case "DELETE_QUOTE_REQUEST":
      return { ...state, error: null, loading: true };
    case "QUOTE_DELETE_COMPLETED":
      return { ...state, loading: false };
    case "LIST_REFRESH":
      return { ...state, data: [] };
    default:
      return state;
  }
};

export default quote;
