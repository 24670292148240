import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import {
  gridRowsUpdated,
  deleteRow,
  insertRow,
  copyRow
} from "../../../actions/build";
import ReactDataGrid from "react-data-grid";

import { columns } from "./columns";
import { Menu } from "react-data-grid-addons";
import GridContextMenu from "./menus/gridContextMenu";

import "./orderEntryForm.css";

const { ContextMenuTrigger } = Menu;

const OrderEntryForm = ({
  rows,
  gridRowsUpdated,
  deleteRow,
  insertRow,
  copyRow,
  heightOffset
}) => {
  const [height, setHeight] = useState(window.innerHeight - heightOffset);
  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return function cleanup() {
      window.removeEventListener("resize", handleResize);
    };
  });

  useEffect(() => {
    setHeight(window.innerHeight - heightOffset);
  }, [heightOffset]);

  function handleResize(e) {
    setHeight(window.innerHeight - heightOffset);
  }

  return (
    <React.Fragment>
      <ReactDataGrid
        contextMenu={
          <GridContextMenu
            onRowDelete={deleteRow}
            onRowInsert={insertRow}
            onRowCopy={copyRow}
          />
        }
        columns={columns()}
        minHeight={height}
        rows={rows}
        rowGetter={i => rows[i]}
        rowsCount={rows.length}
        onGridRowsUpdated={gridRowsUpdated}
        enableCellSelect={true}
        enableRowSelect={null}
        RowsContainer={ContextMenuTrigger}
        rowScrollTimeout={null}
      />
    </React.Fragment>
  );
};

OrderEntryForm.propTypes = {
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      qty: PropTypes.number,
      fabricAndColor: PropTypes.string,
      fabric: PropTypes.string,
      color: PropTypes.string,
      motor: PropTypes.string,
      width: PropTypes.string,
      length: PropTypes.string,
      measurement: PropTypes.string,
      rollerOption: PropTypes.string,
      reverseRoll: PropTypes.string,
      clutch: PropTypes.string,
      chain: PropTypes.string,
      hem: PropTypes.string,
      treatment: PropTypes.string,
      addon: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
      location: PropTypes.string,
      special: PropTypes.string
    })
  ).isRequired,
  gridRowsUpdated: PropTypes.func.isRequired,
  insertRow: PropTypes.func.isRequired,
  deleteRow: PropTypes.func.isRequired,
  copyRow: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  rows: state.build.rows
});

const mapDispatchToProps = dispatch => ({
  gridRowsUpdated: (fromRow, toRow, updated) =>
    dispatch(gridRowsUpdated(fromRow, toRow, updated)),
  insertRow: idx => dispatch(insertRow(idx)),
  deleteRow: idx => dispatch(deleteRow(idx)),
  copyRow: idx => dispatch(copyRow(idx))
});

export default connect(mapStateToProps, mapDispatchToProps)(OrderEntryForm);
