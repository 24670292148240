import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";

import { Button, Modal, Form, Container, Row, Col } from "react-bootstrap";

import moment from "moment";

import QuoteTable from "../quoteTable/table";
import ConfirmButton from "../button/confirmButton";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import "react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css";
import "./index.css";

const dateFormat = "LLLL";
function timeStampFormatter(cell, row) {
  return moment(row.date).format(dateFormat);
}

export const QUOTE_VALID_FOR = 180;
const DAYS_TIL_EXPIRATION = 30;
function expiration(timestamp) {
  const date = new Date(timestamp);
  const now = new Date();

  // Set times to midnight
  date.setHours(0, 0, 0, 0);
  now.setHours(0, 0, 0, 0);

  // get total seconds between two dates
  var res = Math.abs(now - date) / 1000;
  var days = Math.floor(res / 86400);

  return QUOTE_VALID_FOR - days;
}

function expirationFormatter(cell, row) {
  const expires_in = expiration(row.date);
  if (expires_in <= 0) {
    return <font color="red">Expired</font>;
  } else if (expires_in < DAYS_TIL_EXPIRATION) {
    return <font color="red">{expires_in}</font>;
  } else {
    return expires_in;
  }
}

const QuoteCollection = ({
  data,
  name,
  handleQuoteClick = null,
  quoteId = "",
  showExpiration = false,
  showStatus = false,
  editMessage = null,
  handleEditClick = null,
  handleOrderClick = null,
  handleDeleteClick = null,
  handleCancelClick = null,
  emptyMessage = ""
}) => {
  const DefaultSizePerPage = 10;

  const [record, setRecord] = useState(null);
  const [sizePerPage, setSizePerPage] = useState(DefaultSizePerPage);

  const hideDialog = () => {
    handleQuoteClick("");
  };

  const previousRecord = () => {
    const index = data.findIndex(row => row.quoteId === quoteId);
    if (index > 0) {
      handleQuoteClick(data[index - 1].quoteId);
    }
  };

  const nextRecord = () => {
    const index = data.findIndex(row => row.quoteId === quoteId);
    if (index < data.length - 1) {
      handleQuoteClick(data[index + 1].quoteId);
    }
  };

  useEffect(() => {
    if (quoteId) {
      // Find the quote
      if (data) {
        var index = data.findIndex(row => {
          return row.quoteId === quoteId;
        });
        if (index >= 0) {
          const row = data[index];
          // If JSON, convert to object

          setRecord(row);
        }
      }
    } else {
      setRecord(null);
    }
  }, [quoteId, data]);

  const quoteEditableAndCancelable = row => {
    return row?.quoteStatus !== "Shipped" && row?.quoteStatus !== "Processing";
  };

  const ActionButtons = ({ row, showView }) => {
    return (
      <span>
        {showView ? (
          <Button
            variant="outline-info"
            size="sm"
            className="mr-1"
            onClick={() => handleQuoteClick(row.quoteId)}
          >
            View
          </Button>
        ) : null}
        {handleEditClick && quoteEditableAndCancelable(row) ? (
          editMessage ? (
            <ConfirmButton
              className="mr-1"
              buttonInfo={{
                message: "Edit",
                variant: "outline-info",
                size: "sm",
                handler: () => handleEditClick(row),
                danger: true
              }}
              message={editMessage}
              title="Edit"
            />
          ) : (
            <Button
              variant="outline-info"
              size="sm"
              className="mr-1"
              onClick={() => handleEditClick(row)}
            >
              Edit
            </Button>
          )
        ) : null}
        {handleDeleteClick ? (
          <ConfirmButton
            className="mr-1"
            buttonInfo={{
              message: "Delete",
              variant: "outline-danger",
              size: "sm",
              handler: () => handleDeleteClick(row),
              danger: true
            }}
            message="Are you sure you want to delete this?"
            title="Delete"
          />
        ) : null}
        {handleOrderClick ? (
          <ConfirmButton
            message="Are you sure you want to order this?"
            title="Order"
            className="mr-1"
            buttonInfo={{
              message: "Order",
              variant: "outline-primary",
              size: "sm",
              handler: () => handleOrderClick(row),
              disabled: expiration(row?.date) <= 0 ? true : false
            }}
          />
        ) : null}
        {handleCancelClick && quoteEditableAndCancelable(row) ? (
          <ConfirmButton
            message="Are you sure you want to cancel this order?"
            title="Cancel"
            className="mr-1"
            buttonInfo={{
              message: "Cancel",
              variant: "outline-danger",
              size: "sm",
              handler: () => handleCancelClick(row)
            }}
          />
        ) : null}
      </span>
    );
  };

  const actionButtonsFormatter = (cell, row) => (
    <ActionButtons row={row} showView={true} />
  );

  const colEvents = {
    onClick: (e, col, colIndex, row, rowIndex) => {
      handleQuoteClick(row.quoteId);
    }
  };

  let columns = [
    { dataField: "quoteId", text: "Quote ID", hidden: true },
    {
      dataField: "dateTime",
      text: "Date",
      formatter: timeStampFormatter,
      events: colEvents
    },
    {
      dataField: "expiration",
      text: "Expires in (days)",
      formatter: expirationFormatter,
      events: colEvents
    },
    {
      dataField: "sidemark",
      text: "Sidemark / PO",
      filter: textFilter(),
      events: colEvents
    },
    {
      dataField: "memo",
      text: "Memo / Ship To",
      filter: textFilter(),
      formatter: (cellContent, row) => <pre>{cellContent}</pre>,
      events: colEvents
    },
    {
      dataField: "quoteStatus",
      text: "Status",
      events: colEvents
    },
    {
      dataField: "actions",
      text: "Actions",
      isDummyField: true,
      formatter: actionButtonsFormatter
    },
    { dataField: "rows", text: "Quote", hidden: true }
  ];

  // Fixup the columns depending on whether we're showing expiration and / or actions
  columns = columns.filter(col => {
    if (col.dataField === "expiration") {
      return showExpiration;
    }
    if (col.dataField === "quoteStatus") {
      return showStatus;
    } else {
      return true;
    }
  });

  // Questions: What happens to an order once it's canceled? Does it become a quote or stay a canceled order?
  // TODO: Move addons to separate section so we can show more information on them?
  // TODO: Is there value showing the total / number of items / add-ons in the listview?
  return (
    <React.Fragment>
      <BootstrapTable
        condensed
        striped
        hover
        bootstrap4
        keyField="quoteId"
        data={data}
        columns={columns}
        noDataIndication={emptyMessage}
        pagination={paginationFactory({
          sizePerPage: sizePerPage,
          onSizePerChange: sizePerPage => {
            setSizePerPage(sizePerPage);
          }
        })}
        filter={filterFactory()}
      />
      <Modal
        size={"lg"}
        show={record !== null}
        dialogClassName={"modal-90w"}
        onHide={hideDialog}
      >
        <React.Fragment>
          <Modal.Header>
            <Modal.Title>
              <Container fluid>
                <Row>
                  <Col xs={10}>{name}</Col>
                  <Col xs={2} className="text-right pr-0">
                    <Button
                      disabled={data && data[0]?.quoteId === quoteId}
                      onClick={previousRecord}
                    >
                      &lt;
                    </Button>
                    &nbsp;
                    <Button
                      disabled={
                        data && data[data?.length - 1]?.quoteId === quoteId
                      }
                      onClick={nextRecord}
                    >
                      &gt;
                    </Button>
                  </Col>
                </Row>
                <Row>
                  <Col xs={{ span: "auto" }}>
                    {moment(record?.date).format(dateFormat)}
                  </Col>
                  <Col xs={2}>&nbsp;</Col>
                  <Col xs={{ span: "auto" }}>{record?.sidemark}</Col>
                </Row>
              </Container>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <QuoteTable rows={record?.rows} addons={record?.addons} />
            {record?.memo ? (
              <Form.Group controlId="quote.memo">
                <Form.Label>Memo / Instructions</Form.Label>
                <Form.Control
                  as="textarea"
                  rows="3"
                  readOnly
                  value={record?.memo}
                />
              </Form.Group>
            ) : (
              <React.Fragment />
            )}
          </Modal.Body>
          <Modal.Footer style={{ justifyContent: "space-between" }}>
            <span>
              <ActionButtons row={record} showView={false} />
            </span>
            <span>
              <Button onClick={hideDialog}>Close</Button>
            </span>
          </Modal.Footer>
        </React.Fragment>
      </Modal>
    </React.Fragment>
  );
};

QuoteCollection.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      qty: PropTypes.number,
      fabric: PropTypes.string,
      color: PropTypes.string,
      motor: PropTypes.string,
      width: PropTypes.string,
      length: PropTypes.string,
      measurement: PropTypes.string,
      rollerOption: PropTypes.string,
      reverseRoll: PropTypes.string,
      clutch: PropTypes.string,
      chain: PropTypes.string,
      hem: PropTypes.string,
      treatment: PropTypes.string,
      addon: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
      location: PropTypes.string,
      special: PropTypes.string,
      price: PropTypes.number
    })
  ),
  name: PropTypes.string.isRequired,
  handleQuoteClick: PropTypes.func,
  quoteId: PropTypes.string,
  showExpiration: PropTypes.bool,
  handleEditClick: PropTypes.func,
  editMessage: PropTypes.string,
  handleOrderClick: PropTypes.func,
  handleDeleteClick: PropTypes.func,
  emptyMessage: PropTypes.string
};

export default QuoteCollection;
