import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import TextField from "@material-ui/core/TextField";

import { verify } from "../../../actions/auth";
import { Alert, Button, Modal } from "react-bootstrap";

function Verify({
  close,
  user,
  verifyToken,
  fields,
  handleFieldTouched,
  handleFormInput,
  msg,
  pathName
}) {
  const { token } = fields;

  function handleToken() {
    // Clear the value in case we have an error
    fields.token = { value: "", isValid: false, touched: false };

    verifyToken(user, token.value, pathName);
  }

  return (
    <React.Fragment>
      <Modal.Header closeButton>
        <Modal.Title id="login-title">Verification</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Enter the verification code sent to the email address you provided:
        <TextField
          required
          margin="dense"
          id="token"
          label="Token"
          type="text"
          fullWidth
          defaultValue={token.value}
          onBlur={handleFieldTouched}
          onChange={handleFormInput}
          error={token.touched && !token.isValid}
          helperText={
            token.touched && !token.isValid
              ? "Please enter the emailed verification code"
              : " "
          }
        />
        {msg ? <Alert variant="danger">{msg}</Alert> : <React.Fragment />}
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={close}>Cancel</Button>
        <Button onClick={handleToken} disabled={!token.isValid}>
          Verify
        </Button>
      </Modal.Footer>
    </React.Fragment>
  );
}

Verify.propTypes = {
  close: PropTypes.func.isRequired,
  user: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  verifyToken: PropTypes.func.isRequired,
  fields: PropTypes.object.isRequired,
  handleFieldTouched: PropTypes.func.isRequired,
  handleFormInput: PropTypes.func.isRequired,
  msg: PropTypes.string
};

const mapStateToProps = state => ({
  user: state.auth.user,
  msg: state.auth.msg
});

const mapDispatchToProps = dispatch => ({
  verifyToken: (user, token, pathName) =>
    dispatch(verify(user, token, pathName))
});

export default connect(mapStateToProps, mapDispatchToProps)(Verify);
