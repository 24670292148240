import React from "react";
const Materials = require("./materials.json");
class InternalShadeMaterials extends React.Component {
  constructor(props) {
    super(props);

    this._fabricMap = {};

    this.fabricOptions = this._initFabricOptions(
      Materials.fabrics,
      this._fabricMap
    );
    this.constants = Materials.constants;
    this.rollerOptions = this._initOptions(Materials.rollers);
    this.chainOptions = this._initOptions(Materials.chains);
    this.treatmentOptions = this._initOptions(Materials.treatments);
    this.hemOptions = this._initOptions(Materials.hems);
    this.addonOptions = this._initOptions(Materials.addons);
    this.motorOptions = this._initOptions(Materials.motors);
    this.orderAddonOptions = Materials.orderAddons;
    this.toDeci = this.toDeci.bind(this);
    this.findFabric = this.findFabric.bind(this);
    this.maxDimensions = this.maxDimensions.bind(this);
  }

  _initParentChildOptions(arr, child) {
    let options = [];
    let optionsIdx = 0;
    for (let i = 0; i < arr.length; i++) {
      if (!arr[i][child]) {
        options.push({ id: `${optionsIdx++}`, text: arr[i].name });
      } else {
        for (let j = 0; j < arr[i][child].length; j++) {
          options.push({
            id: `${optionsIdx++}`,
            text: `${arr[i].name} ${arr[i][child][j].name}`
          });
        }
      }
    }

    return options;
  }

  _initOptions(arr) {
    let options = [];
    for (let i = 0; i < arr.length; i++) {
      options.push({ id: `${i}`, text: arr[i].name });
    }

    return options;
  }

  _initFabricOptions(fabrics, fabricMap) {
    // map fabric and colors to a fabric since the price is the same for all colrs in fabric
    let fabricOptions = [];
    for (let key in fabrics) {
      let fabric = fabrics[key];
      for (let c = 0; c < fabric.colors.length; c++) {
        // generate list of items for dropdown
        const title = fabric.name + " " + fabric.colors[c];
        fabricOptions.push({ id: `${key}-${c}`, title: title });

        // store mapping of name and color to fabric
        fabricMap[title] = {
          name: fabric.name,
          color: fabric.colors[c]
        };
      }
    }

    // Sort the fabric Options
    fabricOptions.sort((a, b) => a.title.localeCompare(b.title));

    return fabricOptions;
  }

  maxDimensions(row, rowKey) {
    let f = this.findFabric(row.fabricAndColor);
    if (!f) {
      console.log(
        "maxDimensions: Unable to find specified fabric",
        row.fabricAndColor
      );
      return false;
    }

    let dimensions = f.dimensions;
    let max = 0;
    switch (rowKey) {
      case "length":
        max = dimensions["lengths"][dimensions["lengths"].length - 1];
        break;
      case "width":
        max = dimensions["widths"][dimensions["widths"].length - 1];
        break;
      default:
        console.log("Invalid row key");
    }

    // check if the value is less than the max
    return {
      validDimension: this.toDeci(row[rowKey]) <= max,
      max: max
    };
  }

  // find a fabric based on the name
  findFabric(name) {
    if (name === null || name === "") {
      return null;
    }
    // look up the fabric by name
    const f = this._fabricMap[name];
    if (f === null || f === undefined) {
      console.log(`unable to find specified fabric: ${name}`);
      return null;
    }

    // find the fabric object and make a copy of it
    let fabric = { ...Materials.fabrics[f.name] };

    // set the selected color
    fabric.color = f.color;

    // return a complete fabric object
    return fabric;
  }

  toDeci(fraction) {
    fraction = fraction.toString();
    var result,
      wholeNum = 0,
      frac,
      deci = 0;
    if (fraction.search("/") >= 0) {
      if (fraction.search(" ") >= 0) {
        wholeNum = fraction.split(" ");
        frac = wholeNum[1];
        wholeNum = parseInt(wholeNum, 10);
      } else {
        frac = fraction;
      }
      if (fraction.search("/") >= 0) {
        frac = frac.split("/");
        deci = parseInt(frac[0], 10) / parseInt(frac[1], 10);
      }
      result = wholeNum + deci;
    } else {
      result = fraction;
    }
    return result;
  }

  _getIndex(size, arr) {
    for (let i = 0; i < arr.length; i++) {
      if (size <= arr[i]) return i;
    }
    return -1;
  }
}

const shadeMaterials = new InternalShadeMaterials();
export default shadeMaterials;
