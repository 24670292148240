import React from "react";
import PropTypes from "prop-types";

import { formatMoney } from "../../util/formatMoney";
import Total from "./total";

import Table from "react-bootstrap/Table";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

// When calculating colspan, we ignore combined fabric and color column
// and the location and special instructions columns which we're spanning
const IGNORED_COLUMNS = 2;

function QuoteTable({ rows, addons }) {
  function renderAddons(lineNumber, addons) {
    if (!addons) return <React.Fragment />;
    return (
      <React.Fragment>
        {Object.keys(addons).map(category => {
          return Object.keys(addons[category]).map(name => {
            const info = addons[category][name];
            return (
              <tr key={lineNumber}>
                <td>
                  <b>{lineNumber++}</b>
                </td>
                <td>{info.qty}</td>
                <td />
                <td />
                <td />
                <td />
                <td />
                <td />
                <td />
                <td />
                <td />
                <td />
                <td />
                <td />
                <td>
                  {category} - {name}
                </td>
                <td>{formatMoney(info.subtotal)}</td>
              </tr>
            );
          });
        })}
      </React.Fragment>
    );
  }

  // See if we have any rows
  const hasRows = rows && rows.filter(row => row.qty > 0).length > 0;
  let lineNumber = 1;
  return (
    <React.Fragment>
      {hasRows || addons ? (
        <Table striped bordered responsive>
          <thead>
            <tr>
              <th />
              <th>#</th>
              <th>Fabric</th>
              <th>Color</th>
              <th>Motor</th>
              <th>Roller Type</th>
              <th>Width</th>
              <th>Window Length</th>
              <th>Measurement Type</th>
              <th>Roll Direction</th>
              <th>Clutch</th>
              <th>Chain</th>
              <th>Hem Type</th>
              <th>Top Treatment</th>
              <th>Add-on(s)</th>
              <th width="80">Subtotal</th>
            </tr>
          </thead>
          <tbody>
            {rows &&
              rows.map((row, index) => {
                return row.qty > 0 ? (
                  <React.Fragment key={index}>
                    <tr key={lineNumber}>
                      <td>
                        <b>{lineNumber++}</b>
                      </td>
                      <td>{row.qty}</td>
                      <td>{row.fabric}</td>
                      <td>{row.color}</td>
                      <td>{row.motor}</td>
                      <td>{row.rollerOption}</td>
                      <td>{row.width}</td>
                      <td>{row.length}</td>
                      <td>{row.measurement}</td>
                      <td>{row.reverseRoll}</td>
                      <td>{row.clutch}</td>
                      <td>{row.chain}</td>
                      <td>{row.hem}</td>
                      <td>{row.treatment}</td>
                      <td>{row.addon}</td>
                      <td>{formatMoney(row.subtotal)}</td>
                    </tr>
                    {row.location ? (
                      <tr>
                        <td colSpan={Object.keys(row).length - IGNORED_COLUMNS}>
                          <Form.Group
                            as={Row}
                            controlId={lineNumber + "-location"}
                          >
                            <Form.Label column sm={2}>
                              Location
                            </Form.Label>
                            <Col sm={10}>
                              <Form.Control
                                as="textarea"
                                rows="1"
                                placeholder="Where the shades go"
                                defaultValue={row.location}
                                disabled
                              />
                            </Col>
                          </Form.Group>
                        </td>
                      </tr>
                    ) : (
                      <React.Fragment />
                    )}
                    {row.special ? (
                      <tr>
                        <td colSpan={Object.keys(row).length - IGNORED_COLUMNS}>
                          <Form.Group
                            as={Row}
                            controlId={lineNumber + "-special"}
                          >
                            <Form.Label column sm={2}>
                              Special Instructions
                            </Form.Label>
                            <Col sm={10}>
                              <Form.Control
                                as="textarea"
                                rows="1"
                                placeholder="How to make them"
                                defaultValue={row.special}
                                disabled
                              />
                            </Col>
                          </Form.Group>
                        </td>
                      </tr>
                    ) : (
                      <React.Fragment />
                    )}
                  </React.Fragment>
                ) : (
                  <React.Fragment key={index} />
                );
              })}
            {renderAddons(lineNumber, addons)}
          </tbody>
        </Table>
      ) : (
        <React.Fragment />
      )}

      <span className="float-right">
        <b>
          Order Total: <Total rows={rows} orderAddons={addons} />
        </b>
      </span>
    </React.Fragment>
  );
}

QuoteTable.propTypes = {
  addons: PropTypes.object,
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      qty: PropTypes.number,
      fabric: PropTypes.string,
      color: PropTypes.string,
      motor: PropTypes.string,
      width: PropTypes.string,
      length: PropTypes.string,
      measurement: PropTypes.string,
      rollerOption: PropTypes.string,
      reverseRoll: PropTypes.string,
      clutch: PropTypes.string,
      chain: PropTypes.string,
      hem: PropTypes.string,
      treatment: PropTypes.string,
      addon: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
      location: PropTypes.string,
      special: PropTypes.string,
      price: PropTypes.number
    })
  )
};

export default QuoteTable;
