import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { Alert, Button, Modal } from "react-bootstrap";
import TextField from "@material-ui/core/TextField";

import { getToken } from "../../../actions/auth";

function Login({
  sendToken,
  toggle,
  close,
  fields,
  handleFieldTouched,
  handleFormInput,
  msg
}) {
  function handleSendToken() {
    sendToken(fields.email.value.toLowerCase());
  }

  const { email } = fields;

  return (
    <React.Fragment>
      <Modal.Header closeButton>
        <Modal.Title id="login-title">Login</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        If you've already registered and have been approved, enter your email
        below and we'll send you a code you can use to log into the system.
        <TextField
          autoFocus
          required
          margin="dense"
          id="email"
          label="Email Address"
          type="email"
          fullWidth
          onBlur={handleFieldTouched}
          onChange={handleFormInput}
          error={email.touched && !email.isValid}
          helperText={
            email.touched && !email.isValid ? "Please enter your email" : " "
          }
          defaultValue={email.value}
        />
        {msg ? <Alert variant="danger">{msg}</Alert> : <React.Fragment />}
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={toggle} className="mr-auto">
          Not registered?
        </Button>
        <Button onClick={close}>Cancel</Button>
        <Button onClick={handleSendToken} disabled={!email.isValid}>
          Get Token
        </Button>
      </Modal.Footer>
    </React.Fragment>
  );
}

Login.propTypes = {
  close: PropTypes.func.isRequired,
  toggle: PropTypes.func.isRequired,
  sendToken: PropTypes.func.isRequired,
  fields: PropTypes.object.isRequired,
  handleFieldTouched: PropTypes.func.isRequired,
  handleFormInput: PropTypes.func.isRequired,
  msg: PropTypes.string
};

const mapStateToProps = state => ({
  msg: state.auth.msg
});

const mapDispatchToProps = dispatch => ({
  sendToken: email => dispatch(getToken(email))
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
