import React from "react";

const InfoFormatter = ({ row }) => {
  if (row.location && row.special) {
    return (
      <div>
        {row.location} - {row.special}
      </div>
    );
  } else if (row.location) {
    return <div>{row.location}</div>;
  } else {
    return <div>{row.special}</div>;
  }
};

export default InfoFormatter;
