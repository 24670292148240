// TODO: Use the current path instead of hardcoding /quotes/ into the URL.
// TOOD: Break the banner and tips into components
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import {
  Container,
  Row,
  Col,
  Card,
  Button,
  Collapse,
  ListGroup
} from "react-bootstrap";

import { Redirect, useParams } from "react-router-dom";

import {
  requestQuotes,
  editQuote,
  placeOrder,
  deleteQuote
} from "../../actions/quote";

import QuoteCollection, {
  QUOTE_VALID_FOR
} from "../../components/quoteCollection";

const Quotes = ({
  loadQuotes,
  editQuote,
  placeOrder,
  deleteQuote,
  loading,
  data,
  error
}) => {
  const [showHelp, setShowHelp] = useState(false);
  const [selected, setSelected] = useState("");

  let params = useParams();
  useEffect(() => {
    if (params.quoteId) {
      setSelected(params.quoteId);
    }
  }, [params.quoteId]);

  // Load the orders
  useEffect(() => {
    if (data?.length === 0) {
      loadQuotes();
    }
  }, [loadQuotes, data]);

  const handleEditClick = quote => {
    setSelected("");
    editQuote(quote);
  };

  const handleOrderClick = quote => {
    setSelected("");
    placeOrder(
      quote.quoteId,
      quote.sidemark,
      quote.memo,
      quote.addons,
      quote.rows
    );
  };

  const handleDeleteClick = quote => {
    setSelected("");
    deleteQuote(quote.quoteId);
  };

  const style = "light";
  return (
    <React.Fragment>
      <Container fluid>
        <Row>
          <Col>
            <Card bg={style}>
              <Card.Body>
                <Card.Title>
                  Saved Quotes
                  <Button
                    variant="outline-info"
                    className="float-right"
                    onClick={() => setShowHelp(!showHelp)}
                  >
                    {showHelp ? "Hide Tips" : "Show Tips"}
                  </Button>
                </Card.Title>
                <Card.Text>
                  Quotes are valid for {QUOTE_VALID_FOR} days from when they
                  were last generated.
                </Card.Text>
                <Collapse in={showHelp}>
                  <ListGroup as="ol">
                    <ListGroup.Item variant={style} as="li">
                      Click on a row below or the view button to view quote
                      details.
                    </ListGroup.Item>
                    <ListGroup.Item variant={style} as="li">
                      Use the action buttons to update / edit a quote, delete a
                      quote, or place an order.
                    </ListGroup.Item>
                    <ListGroup.Item variant={style} as="li">
                      Filter sidemarks and memos by entering text into the
                      header.
                    </ListGroup.Item>
                  </ListGroup>
                </Collapse>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>&nbsp;</Col>
        </Row>
      </Container>

      {loading ? (
        <center>
          <img alt="processing" src="/processing.gif" />
        </center>
      ) : (
        <React.Fragment>
          <Redirect to={"/quotes/" + selected} />
          <QuoteCollection
            data={data}
            name="Quote"
            handleQuoteClick={setSelected}
            quoteId={selected}
            showExpiration
            handleDeleteClick={handleDeleteClick}
            handleEditClick={handleEditClick}
            handleOrderClick={handleOrderClick}
            emptyMessage={
              error
                ? `An error has occurred. Please contact Hauser Shade: ${error}`
                : "No quotes"
            }
          />
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

Quotes.propTypes = {
  loadQuotes: PropTypes.func.isRequired,
  editQuote: PropTypes.func.isRequired,
  placeOrder: PropTypes.func.isRequired,
  deleteQuote: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  data: PropTypes.array,
  error: PropTypes.string
};

const mapStateToProps = state => ({
  data: state.quote.data,
  error: state.quote.error,
  loading: state.quote.loading
});

const mapDispatchToProps = dispatch => ({
  loadQuotes: () => dispatch(requestQuotes()),
  editQuote: quote => dispatch(editQuote(quote)),
  placeOrder: (quoteId, sidemark, memo, addons, rows) =>
    dispatch(placeOrder(quoteId, sidemark, memo, addons, rows)),
  deleteQuote: quoteId => dispatch(deleteQuote(quoteId))
});

export default connect(mapStateToProps, mapDispatchToProps)(Quotes);
