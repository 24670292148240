import React from "react";
import PropTypes from "prop-types";

const FabricFormatter = ({ finder, value }) => {
  const fabric = finder(value);
  return <div style={{ color: fabric ? "" : "red" }}>{value}</div>;
};

FabricFormatter.propTypes = {
  finder: PropTypes.func.isRequired,
  value: PropTypes.string
};

export default FabricFormatter;
