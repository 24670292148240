import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { Alert, Button, Container, Row, Col } from "react-bootstrap";

import TextField from "@material-ui/core/TextField";

import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";

import { register } from "../../../actions/auth";

function Register({
  close,
  toggle,
  signup,
  fields,
  handleFieldTouched,
  handleFormInput,
  msg
}) {
  function isFormValid() {
    // check if the form is valid
    let isFormValid = true;
    for (const [key, value] of Object.entries(fields)) {
      if (key === "token") continue;
      isFormValid = isFormValid && value.isValid;
    }

    return isFormValid;
  }

  function handleRegister() {
    // remove touched and isValid
    let data = {};
    for (const [key, value] of Object.entries(fields)) {
      data[key] = value.value;
    }

    // convert email field to lowercase
    data.email = data.email.toLowerCase();

    signup(data);
  }

  const {
    first,
    last,
    company,
    email,
    phone,
    address,
    city,
    state,
    zip,
    caResaleNumber
  } = fields;
  return (
    <React.Fragment>
      <DialogContent>
        <DialogContentText>
          This application is only available to Window Covering Retailers. If
          you feel that you qualify, please sign up below.
        </DialogContentText>
        <Container fluid>
          <Row>
            <Col>
              <TextField
                autoFocus
                required
                margin="dense"
                id="first"
                label="First Name"
                type="text"
                fullWidth
                defaultValue={first.value}
                onBlur={handleFieldTouched}
                onChange={handleFormInput}
                error={first.touched && !first.isValid}
                helperText={
                  first.touched && !first.isValid
                    ? "Please enter your first name"
                    : " "
                }
              />
            </Col>
            <Col>
              <TextField
                autoFocus
                required
                margin="dense"
                id="last"
                label="Last Name"
                type="text"
                fullWidth
                defaultValue={last.value}
                onBlur={handleFieldTouched}
                onChange={handleFormInput}
                error={last.touched && !last.isValid}
                helperText={
                  last.touched && !last.isValid
                    ? "Please enter your last name"
                    : " "
                }
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <TextField
                required
                margin="dense"
                id="company"
                label="Company"
                type="text"
                fullWidth
                defaultValue={company.value}
                onBlur={handleFieldTouched}
                onChange={handleFormInput}
                error={company.touched && !company.isValid}
                helperText={
                  company.touched && !company.isValid
                    ? "Please enter your company"
                    : " "
                }
              />
            </Col>
            <Col>
              <TextField
                margin="dense"
                id="caResaleNumber"
                label="California Resale Number"
                type="text"
                fullWidth
                defaultValue={caResaleNumber.value}
                onChange={handleFormInput}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <TextField
                required
                margin="dense"
                id="address"
                label="Company Address"
                type="text"
                fullWidth
                defaultValue={address.value}
                onBlur={handleFieldTouched}
                onChange={handleFormInput}
                error={address.touched && !address.isValid}
                helperText={
                  address.touched && !address.isValid
                    ? "Please enter your company address"
                    : " "
                }
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <TextField
                required
                margin="dense"
                id="city"
                label="City"
                type="text"
                fullWidth
                defaultValue={city.value}
                onBlur={handleFieldTouched}
                onChange={handleFormInput}
                error={city.touched && !city.isValid}
                helperText={
                  city.touched && !city.isValid ? "Please enter your city" : " "
                }
              />
            </Col>
            <Col>
              <TextField
                required
                margin="dense"
                name="state"
                label="State"
                value={state.value}
                select
                SelectProps={{
                  native: true
                }}
                fullWidth
                onBlur={handleFieldTouched}
                onChange={handleFormInput}
                error={state.touched && !state.isValid}
                helperText={
                  state.touched && !state.isValid
                    ? "Please select your state"
                    : " "
                }
              >
                <option disabled hidden value="" />
                <option value="AL">Alabama</option>
                <option value="AK">Alaska</option>
                <option value="AZ">Arizona</option>
                <option value="AR">Arkansas</option>
                <option value="CA">California</option>
                <option value="CO">Colorado</option>
                <option value="CT">Connecticut</option>
                <option value="DE">Delaware</option>
                <option value="FL">Florida</option>
                <option value="GA">Georgia</option>
                <option value="HI">Hawaii</option>
                <option value="ID">Idaho</option>
                <option value="IL">Illinois</option>
                <option value="IN">Indiana</option>
                <option value="IA">Iowa</option>
                <option value="KS">Kansas</option>
                <option value="KY">Kentucky</option>
                <option value="LA">Louisiana</option>
                <option value="ME">Maine</option>
                <option value="MD">Maryland</option>
                <option value="MA">Massachusetts</option>
                <option value="MI">Michigan</option>
                <option value="MN">Minnesota</option>
                <option value="MS">Mississippi</option>
                <option value="MO">Missouri</option>
                <option value="MT">Montana</option>
                <option value="NE">Nebraska</option>
                <option value="NV">Nevada</option>
                <option value="NH">New Hampshire</option>
                <option value="NJ">New Jersey</option>
                <option value="NM">New Mexico</option>
                <option value="NY">North York</option>
                <option value="NC">North Carolina</option>
                <option value="ND">North Dakota</option>
                <option value="OH">Ohio</option>
                <option value="OK">Oklahoma</option>
                <option value="OR">Oregon</option>
                <option value="PA">Pennsylvania</option>
                <option value="RI">Rhode Island</option>
                <option value="SC">South Carolina</option>
                <option value="SD">South Dakota</option>
                <option value="TN">Tennessee</option>
                <option value="TX">Texas</option>
                <option value="UT">Utah</option>
                <option value="VT">Vermont</option>
                <option value="VA">Virginia</option>
                <option value="WA">Washington</option>
                <option value="WV">West Virginia</option>
                <option value="WI">Wisconsin</option>
                <option value="WY">Wyoming</option>
              </TextField>
            </Col>
            <Col>
              <TextField
                required
                margin="dense"
                name="zip"
                label="Zip"
                type="text"
                fullWidth
                defaultValue={zip.value}
                onBlur={handleFieldTouched}
                onChange={handleFormInput}
                error={zip.touched && !zip.isValid}
                helperText={
                  zip.touched && !zip.isValid ? "Please enter your zip" : " "
                }
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <TextField
                required
                margin="dense"
                id="email"
                label="Email Address"
                type="email"
                fullWidth
                defaultValue={email.value}
                onBlur={handleFieldTouched}
                onChange={handleFormInput}
                error={email.touched && !email.isValid}
                helperText={
                  email.touched && !email.isValid
                    ? "Please enter your email"
                    : " "
                }
              />
            </Col>
            <Col>
              <TextField
                required
                margin="dense"
                id="phone"
                label="Phone 123-456-7890"
                type="tel"
                fullWidth
                defaultValue={phone.value}
                onBlur={handleFieldTouched}
                onChange={handleFormInput}
                error={phone.touched && !phone.isValid}
                helperText={
                  phone.touched && !phone.isValid
                    ? "Please enter your phone"
                    : " "
                }
              />
            </Col>
          </Row>
          <Row>
            <Col>
              {msg ? <Alert variant="danger">{msg}</Alert> : <React.Fragment />}
            </Col>
          </Row>
        </Container>
      </DialogContent>
      <DialogActions>
        <Button onClick={toggle} className="mr-auto">
          Already registered?
        </Button>
        <Button onClick={close}>Cancel</Button>
        <Button onClick={handleRegister} disabled={!isFormValid()}>
          Register
        </Button>
      </DialogActions>
    </React.Fragment>
  );
}

Register.propTypes = {
  close: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  msg: state.auth.msg
});

const mapDispatchToProps = dispatch => ({
  signup: data => dispatch(register(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(Register);
