import React from "react";

import RegexFormatter from "./formatters/regexFormatter";
import DimensionFormatter from "./formatters/dimensionFormatter";
import FabricFormatter from "./formatters/fabricFormatter";
import AddonFormatter from "./formatters/addonsFormatter";
import CharacterFilterEditor from "./editors/filterKeysEditor";
import { Editors } from "react-data-grid-addons";
import shadeMaterials from "../../../util/shadeMaterials";
import InfoEditor from "./editors/infoEditor";
import InfoFormatter from "./formatters/infoFormatter";

const FabricsEditor = (
  <Editors.AutoComplete options={shadeMaterials.fabricOptions} />
);

const QuantityEditor = (
  <CharacterFilterEditor onKeyPress={onlyAllowWholeNumbers} />
);
const DimensionEditor = (
  <CharacterFilterEditor onKeyPress={onlyAllowNumbersAndFractions} />
);

function onlyAllowWholeNumbers(e) {
  if (e.charCode < 48 || e.charCode > 57) {
    e.preventDefault();
    e.stopPropagation();
  }
}

const LineNumberFormatter = ({ rowIdx }) => {
  return <div>{rowIdx + 1}</div>;
};

function onlyAllowNumbersAndFractions(e) {
  // Ensure that it is a number, space, period, or /
  if (
    (e.charCode < 47 || e.charCode > 57) &&
    e.charCode !== 32 &&
    e.charCode !== 46
  ) {
    e.preventDefault();
  }
}

export const DimensionRegex = /^\d{1,3}([ ]?(\d{1,2}[/](2|4|8|16|32))?|\.\d{1,3})$/;

const defaultColumnProperties = {
  resizable: true
};

export function columns() {
  return [
    {
      key: "index",
      name: "",
      width: 35,
      formatter: <LineNumberFormatter />
    },
    {
      key: "qty",
      name: "Qty",
      width: 40,
      formatter: <RegexFormatter regex={/^\d+/} />,
      editor: QuantityEditor
    },
    {
      key: "fabricAndColor",
      name: "Fabric & Color",
      editor: FabricsEditor,
      formatter: <FabricFormatter finder={shadeMaterials.findFabric} />
    },
    {
      key: "rollerOption",
      name: "Roller",
      width: 115,
      editor: <Editors.DropDownEditor options={shadeMaterials.rollerOptions} />
    },
    {
      key: "motor",
      name: "Motor",
      width: 125,
      editor: <Editors.DropDownEditor options={shadeMaterials.motorOptions} />
    },
    {
      key: "width",
      name: "Width",
      width: 75,
      formatter: (
        <DimensionFormatter
          regex={DimensionRegex}
          rowKey={"width"}
          max={shadeMaterials.maxDimensions}
        />
      ),
      editor: DimensionEditor
    },
    {
      key: "length",
      name: "Length",
      width: 75,
      formatter: (
        <DimensionFormatter
          regex={DimensionRegex}
          rowKey={"length"}
          max={shadeMaterials.maxDimensions}
        />
      ),
      editor: DimensionEditor
    },
    {
      key: "measurement",
      name: "Measure",
      width: 85,
      editor: (
        <Editors.DropDownEditor
          options={[
            { id: 0, text: "Inside" },
            { id: 1, text: "Outside" },
            { id: 2, text: "Cloth" }
          ]}
        />
      )
    },
    {
      key: "reverseRoll",
      name: "Roll Direction",
      width: 110,
      editor: (
        <Editors.DropDownEditor
          options={[{ id: 0, text: "Standard" }, { id: 1, text: "Reverse" }]}
        />
      )
    },
    {
      key: "clutch",
      name: "Control Side",
      width: 105,
      editor: (
        <Editors.DropDownEditor
          options={[
            { id: 0, text: "" },
            { id: 1, text: "Left" },
            { id: 2, text: "Right" }
          ]}
        />
      )
    },
    {
      key: "chain",
      name: "Chain",
      width: 105,
      editor: <Editors.DropDownEditor options={shadeMaterials.chainOptions} />
    },
    {
      key: "hem",
      name: "Hem Type",
      width: 125,
      editor: <Editors.DropDownEditor options={shadeMaterials.hemOptions} />
    },
    {
      key: "treatment",
      name: "Top Treatment",
      width: 125,
      editor: (
        <Editors.DropDownEditor options={shadeMaterials.treatmentOptions} />
      )
    },
    {
      key: "addon",
      name: "Add-on",
      width: 125,
      formatter: <AddonFormatter />,
      editor: <Editors.DropDownEditor options={shadeMaterials.addonOptions} />
    },
    {
      key: "info",
      name: "Info",
      width: 125,
      formatter: <InfoFormatter />,
      editor: <InfoEditor />
    }
  ].map(c => ({ ...c, ...defaultColumnProperties }));
}
