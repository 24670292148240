import React, { useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { Modal } from "react-bootstrap";
import validator from "validator";

import LoginDialog from "./login/Login";
import RegisterDialog from "./register/Register";
import Verify from "./verify/verify";
import { hideAuth, toggleAuth } from "../../actions/auth";

const AuthDialog = props => {
  const [fields, setFields] = useState({
    first: { touched: false, isValid: false, value: "" },
    last: { touched: false, isValid: false, value: "" },
    company: { touched: false, isValid: false, value: "" },
    email: { touched: false, isValid: false, value: "" },
    phone: { touched: false, isValid: false, value: "" },
    address: { touched: false, isValid: false, value: "" },
    city: { touched: false, isValid: false, value: "" },
    state: { touched: false, isValid: false, value: "" },
    zip: { touched: false, isValid: false, value: "" },
    caResaleNumber: { touched: true, isValid: true, value: "" },
    token: { touched: false, isValid: false, value: "" }
  });

  function handleFormInput(event) {
    let fieldValue = event.target.value;
    const fieldName = event.target.id || event.target.name;

    let isValid = false;
    switch (fieldName) {
      case "email":
        isValid = validator.isEmail(fieldValue);
        break;
      case "phone":
        if (fieldValue) {
          if (fieldValue[0] === "1") {
            fieldValue = fieldValue.substring(1);
          } else if (fieldValue[0] === "+" && fieldValue[1] === "1") {
            fieldValue = fieldValue.substring(2);
          }
        }
        isValid = validator.isMobilePhone(fieldValue, ["en-US"]);
        break;
      case "caResaleNumber":
        isValid = true;
        break;
      default:
        isValid = fieldValue.trim() !== "";
    }

    setFields({
      ...fields,
      [fieldName]: {
        ...fields[fieldName],
        value: fieldValue,
        isValid: isValid
      }
    });
  }

  function handleTouched(event) {
    const fieldName = event.target.id || event.target.name;

    setFields({
      ...fields,
      [fieldName]: { ...fields[fieldName], touched: true }
    });
  }

  const showState = ({ status, hideAuth, toggleAuth }) => {
    switch (status) {
      case "Login":
        return (
          <LoginDialog
            close={hideAuth}
            toggle={toggleAuth}
            fields={fields}
            handleFormInput={handleFormInput}
            handleFieldTouched={handleTouched}
          />
        );
      case "Registration":
        return (
          <RegisterDialog
            close={hideAuth}
            toggle={toggleAuth}
            fields={fields}
            handleFormInput={handleFormInput}
            handleFieldTouched={handleTouched}
          />
        );
      case "Verify":
        return (
          <Verify
            close={hideAuth}
            fields={fields}
            handleFormInput={handleFormInput}
            handleFieldTouched={handleTouched}
            pathName={props.pathName}
          />
        );
      case "Processing":
        return (
          <center>
            <img
              src="/processing.gif"
              height="200"
              width="200"
              alt="processing"
            />
          </center>
        );
      default:
      // Don't show anything
    }
  };

  if (!props.session) {
    return (
      <React.Fragment>
        <Modal
          centered
          show={props.status !== null}
          onHide={props.hideAuth}
          size={props.status === "Processing" ? "sm" : "lg"}
          aria-labelledby="form-dialog-title"
        >
          {showState(props)}
        </Modal>
      </React.Fragment>
    );
  }

  return <React.Fragment />;
};

AuthDialog.propTypes = {
  status: PropTypes.string,
  hideAuth: PropTypes.func.isRequired,
  toggleAuth: PropTypes.func.isRequired,
  session: PropTypes.object
};

const mapStateToProps = state => ({
  status: state.auth.status,
  session: state.auth.session
});

const mapDispatchToProps = dispatch => ({
  toggleAuth: () => dispatch(toggleAuth()),
  hideAuth: () => dispatch(hideAuth())
});

export default connect(mapStateToProps, mapDispatchToProps)(AuthDialog);
