import React from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";

// Custom regex validator
const DimensionFormatter = ({ regex, rows, rowIdx, rowKey, max, value }) => {
  function display(value) {
    return value != null && value !== 0 ? `${value}` : "";
  }

  let valid = regex.test(value);
  if (valid) {
    let result = max(rows[rowIdx], rowKey);
    valid = result.validDimension;
  }

  return <div style={{ color: valid ? "" : "red" }}>{display(value)}</div>;
};

DimensionFormatter.propTypes = {
  regex: PropTypes.object.isRequired,
  rows: PropTypes.array.isRequired,
  rowIdx: PropTypes.number,
  rowKey: PropTypes.string.isRequired,
  max: PropTypes.func.isRequired,
  value: PropTypes.any
};

const mapStateToProps = state => ({
  rows: state.build.rows
});

export default connect(mapStateToProps, null)(DimensionFormatter);
