import { Auth } from "aws-amplify";
import history from "../history";

export const showAuth = (status, msg = null) => ({
  type: "SHOW_AUTH",
  status: status,
  msg: msg
});

// Switch between registration and login
export const toggleAuth = () => ({ type: "TOGGLE_AUTH" });

// Hide the authentication dialog
export const hideAuth = () => ({ type: "HIDE_AUTH" });

// Waiting on AJAX call
const processing = () => ({ type: "PROCESSING" });

// Token sent to user
const tokenSent = cognitoUser => ({ type: "VERIFY", user: cognitoUser });

export const verify = (user, code, pathName) => async dispatch => {
  dispatch(processing());

  if (user.challengeName) {
    // Login
    await Auth.sendCustomChallengeAnswer(user, code)
      .then(async data => {
        const session = await isAuthenticated();
        if (session) {
          dispatch(authenticated(session));
          history.push(pathName);
        } else {
          dispatch(
            showAuth(
              "Verify",
              "That's not the right code. Please check your email and try again."
            )
          );
        }
      })
      .catch(err => dispatch(showAuth("Verify", err.message || err)));
  } else {
    // Complete registration
    await Auth.confirmSignUp(user, code)
      .then(data =>
        dispatch(
          showAuth(
            null,
            "Registration complete. We'll review your information and get back to you shortly."
          )
        )
      )
      .catch(err => dispatch(showAuth("Verify", err.message || err)));
  }
};

// User has successfully authenticated
const authenticated = cognitoUser => ({
  type: "AUTHENTICATED",
  session: cognitoUser
});

//////////////////////
//
// Login
//

// Get token for login
export const getToken = email => async dispatch => {
  dispatch(processing());

  try {
    let cognitoUser = await Auth.signIn(email);
    dispatch(tokenSent(cognitoUser));
  } catch (err) {
    if (err.code === "NotAuthorizedException") {
      dispatch(
        showAuth(
          "Login",
          "Your account is pending review. We'll notify you as soon as you've been approved."
        )
      );
    } else {
      dispatch(showAuth("Login", err.message || err));
    }
  }
};

//////////////////////
//
// Registration
//
const getRandomString = bytes => {
  const randomValues = new Uint8Array(bytes);
  window.crypto.getRandomValues(randomValues);
  return Array.from(randomValues)
    .map(intToHex)
    .join("");
};

const intToHex = nr => {
  return nr.toString(16).padStart(2, "0");
};

const verifyRegistration = user => ({ type: "VERIFY", user: user });
export const register = attributes => async dispatch => {
  let errMsg = null;

  try {
    dispatch(processing());
    const address = {
      street_address: attributes.address,
      locality: attributes.city,
      region: attributes.state,
      postal_code: attributes.zip,
      country: "USA"
    };

    // phone strip formatting and add in country code
    const phone = "+1" + attributes.phone.replace(/\D/g, "");

    const params = {
      username: attributes.email,
      password: getRandomString(30),
      attributes: {
        name: attributes.first + " " + attributes.last,
        given_name: attributes.first,
        family_name: attributes.last,
        email: attributes.email,
        phone_number: phone,
        address: JSON.stringify(address),
        "custom:factor": "100",
        "custom:company": attributes.company
      }
    };
    const caResaleNumber = attributes["caResaleNumber"];
    if (caResaleNumber) {
      params.attributes["custom:caResaleNumber"] = caResaleNumber;
    }

    await Auth.signUp(params)
      .then(function(result) {
        dispatch(verifyRegistration(attributes.email));
      })
      .catch(async function(err) {
        let handled = false;
        if (err.code === "UsernameExistsException") {
          // if the user has not confirmed the account, give them another chance
          await Auth.resendSignUp(attributes.email)
            .then(function(result) {
              handled = true;
              dispatch(verifyRegistration(attributes.email));
            })
            .catch(function(err) {
              handled = true;
              if (err.code === "NotAuthorizedException") {
                dispatch(
                  showAuth(
                    "Registration",
                    "Your account is pending review. We'll notify you as soon as you've been approved."
                  )
                );
              } else {
                dispatch(showAuth("Registration", err.message || err));
              }
            });
        }
        if (!handled) {
          dispatch(showAuth("Registration", err.message || err));
        }
      });
  } catch (err) {
    dispatch(showAuth("Registration", err.message || err));
  }

  return errMsg;
};

//////////////////////
//
// Logout
//

// User is logged out
const loggedOut = () => ({ type: "LOGGED_OUT" });

// log the user out
export const logout = () => async dispatch => {
  dispatch(processing());
  await Auth.signOut();

  dispatch(loggedOut());

  // Take the user back to welcome
  history.push("/");
};

//////////////////////
//
// Helper Functions
//

// User is logged in
export const isLoggedIn = user => async dispatch => {
  const session = await isAuthenticated();
  if (session) {
    dispatch(authenticated(session));
    return true;
  }
  return false;
};

// Determines whether the user is authenticated
const isAuthenticated = async () => {
  try {
    return await Auth.currentSession();
  } catch {
    return null;
  }
};
