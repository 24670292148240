import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { Router, Route, Switch } from "react-router-dom";
import NotFound from "./views/notfound/index";
import history from "./history";

import Navbar from "./components/NavBar";
import Welcome from "./views/welcome/Welcome";
import Home from "./views/home/index";
import Quotes from "./views/quotes/index";
import Orders from "./views/orders/index";
import AuthenticatedRoute from "./components/route/AuthenticatedRoute";

import Amplify from "aws-amplify";
import { isLoggedIn } from "./actions/auth";

Amplify.configure({
  Auth: {
    identityPoolId: process.env.REACT_APP_IDENITY_POOL_ID,
    region: process.env.REACT_APP_REGION,
    userPoolId: process.env.REACT_APP_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID
  },
  API: {
    endpoints: [
      {
        name: "APIGatewayAPI",
        endpoint: process.env.REACT_APP_DOMAIN,
        region: process.env.REACT_APP_REGION
      }
    ]
  }
});

// Putting up a processing gif until we've decided whether we're logged in already or not
const App = ({ isLoggedIn, session, msg }) => {
  const [processing, setProcessing] = useState(true);
  const [error, setError] = useState("");

  useEffect(() => {
    // Determine if the user is already logged in
    isLoggedIn()
      .then(isLoggedIn => {
        setProcessing(false);
        if (
          isLoggedIn &&
          (window.location.pathname === "" || window.location.pathname === "/")
        ) {
          history.push("/home");
        }
      })
      .catch(error => {
        setProcessing(false);
        setError(error);
      });
  }, [isLoggedIn]);

  const appName = "Quote and Order";

  if (error) {
    return (
      <React.Fragment>
        <center>
          <h1>Something went wrong</h1>
          <p>Please notify Hauser Shade about the following error:</p>
          <p>{error.message || error}</p>
        </center>
      </React.Fragment>
    );
  }

  if (processing) {
    return (
      <center>
        <img alt="processing" src="/processing.gif" />
      </center>
    );
  }

  return (
    <Router history={history}>
      <React.Fragment>
        <Route
          path="/"
          render={props => <Navbar {...props} appName={appName} />}
        />
        <Switch>
          <Route
            exact
            path="/"
            render={props => <Welcome {...props} appName={appName} msg={msg} />}
          />

          <AuthenticatedRoute
            exact
            path="/home"
            component={Home}
            session={session}
          />
          <AuthenticatedRoute
            path="/quotes/:quoteId?"
            component={Quotes}
            session={session}
          />
          <AuthenticatedRoute
            path="/orders/:quoteId?"
            component={Orders}
            session={session}
          />
          <Route component={NotFound} />
        </Switch>
      </React.Fragment>
    </Router>
  );
};

App.propTypes = {
  isLoggedIn: PropTypes.func.isRequired,
  session: PropTypes.object,
  msg: PropTypes.string
};

const mapStateToProps = state => ({
  session: state.auth.session,
  msg: state.auth.msg
});

const mapDispatchToProps = dispatch => ({
  isLoggedIn: () => dispatch(isLoggedIn(null))
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
