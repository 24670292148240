import React, { useState, useEffect } from "react";

import { Modal, Table, Button, Tabs, Tab } from "react-bootstrap";
import shadeMaterials from "../../../util/shadeMaterials";

const AddonsModal = ({ addons, update }) => {
  const [show, setShow] = useState(false);
  const [hasQuantity, setHasQuantity] = useState(false);

  useEffect(() => {
    // Determine if any add=ons any quantities selected
    let hasQty = false;
    if (addons) {
      for (const addon of Object.values(addons)) {
        for (const info of Object.values(addon)) {
          if (info.qty > 0) {
            hasQty = true;
            break;
          }
        }
        if (hasQty) {
          break;
        }
      }
    }

    setHasQuantity(hasQty);
  }, [addons]);

  function handleClose() {
    setShow(false);
  }
  function handleAddonsClick() {
    setShow(true);
  }

  function handleKeyPress(e) {
    const keyCode = e.keyCode || e.which;
    const keyValue = String.fromCharCode(keyCode);
    if (/\+|-/.test(keyValue)) {
      e.preventDefault();
    }
  }

  function handleOnBlur(addons, category, addon) {
    // convert empty field to zero
    if (
      addons[category] &&
      addons[category][addon] &&
      addons[category][addon].qty === ""
    ) {
      update(category, addon, 0);
    }
  }

  function handleChangeCount(category, addon, e) {
    update(category, addon, e.target.value);
  }

  return (
    <React.Fragment>
      <span className="float-right">
        <Button variant="secondary" onClick={handleAddonsClick}>
          Add Remotes/Special Items
        </Button>
        &nbsp; {hasQuantity ? "Specified" : "None"}
      </span>
      {show ? (
        <Modal show={show} onHide={handleClose} size="lg">
          <Modal.Header>
            <Modal.Title id="contained-modal-title-lg">
              Add Remotes/Special Items
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Tabs>
              {shadeMaterials.orderAddonOptions.map((category, index) => (
                <Tab key={category.name} eventKey={index} title={category.name}>
                  <Table striped bordered hover size="sm">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>{category.name} Add-on</th>
                      </tr>
                    </thead>
                    <tbody>
                      {category.addons.map((addon, index) => {
                        let qty = 0;
                        if (
                          addons[category.name] &&
                          addons[category.name][addon.name]
                        ) {
                          qty = addons[category.name][addon.name].qty;
                        }
                        return (
                          <tr key={addon.name}>
                            <td>
                              <input
                                type="text"
                                value={qty}
                                onBlur={() =>
                                  handleOnBlur(
                                    addons,
                                    category.name,
                                    addon.name
                                  )
                                }
                                onKeyPress={handleKeyPress}
                                onChange={e =>
                                  handleChangeCount(
                                    category.name,
                                    addon.name,
                                    e
                                  )
                                }
                              />
                            </td>
                            <td>{addon.name}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </Tab>
              ))}
            </Tabs>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={handleClose}>Done</Button>
          </Modal.Footer>
        </Modal>
      ) : (
        <React.Fragment />
      )}
    </React.Fragment>
  );
};

export default AddonsModal;
