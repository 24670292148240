import React, { useState, useLayoutEffect, useRef } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import {
  updateSidemark,
  updateMemo,
  updateOrderAddons
} from "../../actions/build";

import AddonsModal from "./orderEntry/addonsModal";

import {
  Container,
  Row,
  Col,
  Card,
  Collapse,
  Button,
  ListGroup,
  InputGroup,
  FormControl,
  Alert
} from "react-bootstrap";

import ClearForm from "./clearForm/clearForm";
import Quote from "./quote/quote";
import OrderEntryForm from "./orderEntry/orderEntryForm";

const TOOLBAR_HEIGHT = 106;

const Home = ({
  sidemark,
  memo,
  updateSidemark,
  updateMemo,
  isOrderValid,
  orderAddons,
  updateOrderAddons,
  msg
}) => {
  const [showHelp, setShowHelp] = useState(false);
  const [height, setHeight] = useState(null);

  const onCollapseChange = () => {
    setHeight(containerRef.current.offsetHeight);
  };

  useLayoutEffect(() => {
    setHeight(containerRef.current.offsetHeight);
  }, []);

  const handleSidemarkChange = event => {
    updateSidemark(event.target.value);
  };

  const handleMemoChange = event => {
    updateMemo(event.target.value);
  };

  const containerRef = useRef(null);
  const style = "light";

  return (
    <React.Fragment>
      <Container fluid ref={containerRef}>
        <Row>
          <Col>
            <Card bg={style}>
              <Card.Body>
                <Card.Title>
                  Welcome to Hauser Shade’s quote portal!
                  <Button
                    variant="outline-info"
                    className="float-right"
                    onClick={() => setShowHelp(!showHelp)}
                  >
                    {showHelp ? "Hide Tips" : "Show Tips"}
                  </Button>
                </Card.Title>
                <Card.Text>
                  Enter information below and click "Get Quote" to generate a
                  quote.
                </Card.Text>
                <Collapse
                  in={showHelp}
                  onEntered={onCollapseChange}
                  onExited={onCollapseChange}
                >
                  <ListGroup as="ol">
                    <ListGroup.Item variant={style} as="li">
                      Double Click Any Cell to edit/add information
                    </ListGroup.Item>
                    <ListGroup.Item variant={style} as="li">
                      Search for fabrics by entering key words (eg. 3%, blackout
                      , white, 2390) in the “Fabric and Color”
                    </ListGroup.Item>
                    <ListGroup.Item variant={style} as="li">
                      Cell Copy cells by dragging the box in the lower right
                      corner of any cell down
                    </ListGroup.Item>
                    <ListGroup.Item variant={style} as="li">
                      Please use inches for measurements (eg. 48 1/4 or 48.25)
                    </ListGroup.Item>
                    <ListGroup.Item variant={style} as="li">
                      Please use your WINDOW height for length to the nearest
                      inch
                    </ListGroup.Item>
                    <ListGroup.Item variant={style} as="li">
                      To ADD or DELETE lines, please right click on the line
                      where you would like to add or delete
                    </ListGroup.Item>
                    <ListGroup.Item variant={style} as="li">
                      All cells must be filled out to receive a quote
                    </ListGroup.Item>
                    <ListGroup.Item variant={style} as="li">
                      After you have pressed the “Get Quote” button, you will be
                      able to enter room locations and special instructions and
                      place the order
                    </ListGroup.Item>
                  </ListGroup>
                </Collapse>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>&nbsp;</Col>
        </Row>
        <Row>
          <Col>
            <ClearForm />
          </Col>
          <Col>
            <InputGroup>
              <InputGroup.Prepend>
                <InputGroup.Text>Sidemark / PO</InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl
                onChange={handleSidemarkChange}
                value={sidemark || ""}
              />
            </InputGroup>
          </Col>
          <Col>
            <InputGroup>
              <InputGroup.Prepend>
                <InputGroup.Text>Memo / Ship To</InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl
                as="textarea"
                placeholder="Anything we need to know"
                onChange={handleMemoChange}
                value={memo || ""}
              />
            </InputGroup>
          </Col>
          <Col>
            <AddonsModal addons={orderAddons} update={updateOrderAddons} />
          </Col>
        </Row>
        <Row>
          <Col>&nbsp;</Col>
        </Row>
      </Container>
      <OrderEntryForm heightOffset={height + TOOLBAR_HEIGHT} />
      <Alert className="mb-0" variant={isOrderValid ? "info" : "warning"}>
        <span>
          {msg}
          <Quote />
        </span>
      </Alert>
    </React.Fragment>
  );
};

Home.propTypes = {
  sidemark: PropTypes.string,
  memo: PropTypes.string,
  updateSidemark: PropTypes.func.isRequired,
  isOrderValid: PropTypes.bool.isRequired,
  orderAddons: PropTypes.object.isRequired,
  updateOrderAddons: PropTypes.func.isRequired,
  msg: PropTypes.string
};

const mapStateToProps = state => ({
  sidemark: state.build.sidemark,
  memo: state.build.memo,
  isOrderValid: state.build.orderEntryStatus.isOrderValid,
  msg: state.build.orderEntryStatus.msg,
  orderAddons: state.build.orderAddons
});

const mapDispatchToProps = dispatch => ({
  updateSidemark: value => dispatch(updateSidemark(value)),
  updateMemo: value => dispatch(updateMemo(value)),
  updateOrderAddons: (category, addon, qty) =>
    dispatch(updateOrderAddons(category, addon, qty))
});

export default connect(mapStateToProps, mapDispatchToProps)(Home);
