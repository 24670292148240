import React from "react";
import PropTypes from "prop-types";

// Custom regex validator
const AddonsFormatter = ({ value }) => {
  // Value can either be a string or an object depending on if it's associated with motors
  let display = "";
  if (typeof value === "object") {
    let quantities = value?.quantities;
    if (quantities) {
      // comma separate the addons
      for (const [addon, qty] of Object.entries(quantities)) {
        display += `${qty} - ${addon}, `;
      }

      if (display.length > 0) {
        // remove the trailing comma and extra space
        display = display.slice(0, -2);
      }
    }
  } else {
    display = value;
  }

  return <div>{display}</div>;
};

AddonsFormatter.propTypes = {
  value: PropTypes.any
};

export default AddonsFormatter;
