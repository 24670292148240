import React from "react";
import PropTypes from "prop-types";

// Custom regex validator
const RegexFormatter = ({ regex, value }) => {

  function display(value) {
    return value != null && value !== 0 ? `${value}` : "";
  }

  return (
    <div style={{ color: regex.test(value) ? "" : "red" }}>
      {display(value)}
    </div>
  );
};

RegexFormatter.propTypes = {
  regex: PropTypes.object.isRequired,
  value: PropTypes.any
};

export default RegexFormatter;
