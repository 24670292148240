import React, { useState } from "react";
import PropTypes from "prop-types";

import { Modal, Button } from "react-bootstrap";

const ConfirmButton = ({ buttonInfo, message, className, title }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = e => {
    e.preventDefault();
    e.stopPropagation();
    setShow(true);
  };
  const handleYes = params => {
    setShow(false);
    buttonInfo.handler(params);
  };

  // default button
  const defaultBtn = {
    disabled: false
  };

  // combine passed parameters with defaults to complete the button
  let button = { ...defaultBtn, ...buttonInfo };

  return (
    <React.Fragment>
      <Button
        className={className}
        variant={button.variant}
        onClick={handleShow}
        disabled={button.disabled}
        size={button.size}
      >
        {button.message}
      </Button>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>{title}</Modal.Header>

        <Modal.Body>{message}</Modal.Body>

        <Modal.Footer>
          <Button variant="primary" onClick={handleYes}>
            Yes
          </Button>
          <Button variant="secondary" onClick={handleClose}>
            No
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
};

ConfirmButton.propTypes = {
  message: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  buttonInfo: PropTypes.shape({
    message: PropTypes.string.isRequired,
    handler: PropTypes.func.isRequired,
    variant: PropTypes.string,
    disabled: PropTypes.bool,
    size: PropTypes.string
  })
};

export default ConfirmButton;
