import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";

import {
  requestOrders,
  editOrder,
  requestCancelOrder
} from "../../actions/order";

import {
  Container,
  Row,
  Col,
  Card,
  Button,
  Collapse,
  ListGroup
} from "react-bootstrap";

import { Redirect, useParams } from "react-router-dom";

import QuoteCollection from "../../components/quoteCollection";

const Orders = ({
  loadOrders,
  editOrder,
  cancelOrder,
  data,
  error,
  loading
}) => {
  const [showHelp, setShowHelp] = useState(false);
  const [selected, setSelected] = useState("");
  let params = useParams();

  // Show the specific order if one is specified
  useEffect(() => {
    if (params.quoteId) {
      setSelected(params.quoteId);
    }
  }, [params.quoteId]);

  // Load the orders
  useEffect(() => {
    loadOrders();
  }, [loadOrders]);

  const handleEditClick = quote => {
    setSelected("");

    editOrder(quote);
  };

  const handleCancelClick = quote => {
    setSelected("");

    cancelOrder(quote.quoteId, data);
  };

  const style = "light";
  return (
    <React.Fragment>
      <Container fluid>
        <Row>
          <Col>
            <Card bg={style}>
              <Card.Body>
                <Card.Title>
                  Orders
                  <Button
                    variant="outline-info"
                    className="float-right"
                    onClick={() => setShowHelp(!showHelp)}
                  >
                    {showHelp ? "Hide Tips" : "Show Tips"}
                  </Button>
                </Card.Title>
                <Card.Text>Orders placed through the system.</Card.Text>
                <Collapse in={showHelp}>
                  <ListGroup as="ol">
                    <ListGroup.Item variant={style} as="li">
                      Click on a row below or the view button to view quote
                      details.
                    </ListGroup.Item>
                    <ListGroup.Item variant={style} as="li">
                      Use the action buttons to edit or cancel an order before
                      we begin working on it.
                    </ListGroup.Item>
                    <ListGroup.Item variant={style} as="li">
                      Filter sidemarks and memos by entering text into the
                      header.
                    </ListGroup.Item>
                  </ListGroup>
                </Collapse>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>&nbsp;</Col>
        </Row>
      </Container>

      {loading ? (
        <center>
          <img alt="processing" src="/processing.gif" />
        </center>
      ) : (
        <React.Fragment>
          <Redirect to={"/orders/" + selected} />
          <QuoteCollection
            data={data}
            name="Order"
            handleQuoteClick={setSelected}
            handleCancelClick={handleCancelClick}
            handleEditClick={handleEditClick}
            editMessage={
              "Are you sure? This will allow you to edit this order, but you'll need to place the order again even if you make no changes."
            }
            quoteId={selected}
            showStatus={true}
            emptyMessage={
              error
                ? `An error has occurred. Please contact Hauser Shade: ${error}`
                : "No orders"
            }
          />
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

Orders.propTypes = {
  loadOrders: PropTypes.func.isRequired,
  editOrder: PropTypes.func.isRequired,
  cancelOrder: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  data: PropTypes.array,
  error: PropTypes.string
};

const mapStateToProps = state => ({
  data: state.order.data,
  error: state.order.error,
  loading: state.order.loading
});

const mapDispatchToProps = dispatch => ({
  loadOrders: () => dispatch(requestOrders()),
  editOrder: order => dispatch(editOrder(order)),
  cancelOrder: (quoteId, data) => dispatch(requestCancelOrder(quoteId, data))
});

export default connect(mapStateToProps, mapDispatchToProps)(Orders);
