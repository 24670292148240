import React from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";

class FilterKeysEditor extends React.Component {
  getStyle() {
    return {
      width: "100%"
    };
  }

  getValue() {
    let updated = {};
    updated[this.props.column.key] = this.getInputNode().value;
    return updated;
  }

  getInputNode() {
    let domNode = ReactDOM.findDOMNode(this);
    if (domNode.tagName === "INPUT") {
      return domNode;
    }

    return domNode.querySelector("input:not([type=hidden])");
  }

  inheritContainerStyles() {
    return true;
  }

  render() {
    return (
      <input
        ref={node => (this.input = node)}
        type="text"
        onKeyPress={this.props.onKeyPress}
        onBlur={this.props.onBlur}
        className="form-control"
        defaultValue={this.props.value}
      />
    );
  }
}

FilterKeysEditor.propTypes = {
  column: PropTypes.object,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onKeyPress: PropTypes.func.isRequired,
  onBlur: PropTypes.func
};

export default FilterKeysEditor;
