import React from "react";
import PropTypes from "prop-types";

import AuthDialog from "../../components/authDialog/AuthDialog";
import AuthLinks from "../../components/AuthLinks/index";

import { Alert, Container, Row, Col, Jumbotron } from "react-bootstrap";

const Welcome = ({ appName, msg, location }) => {
  return (
    <React.Fragment>
      <Jumbotron>
        <Container>
          <Row>
            <Col>
              <img
                src="Hauser-Shade-logo-landscape.jpg"
                alt="Hauser Shade Logo"
              />
            </Col>
          </Row>
          <Row>
            <Col>&nbsp;</Col>
          </Row>
          <Row>
            <Col>
              {msg ? (
                <Alert variant="success">{msg}</Alert>
              ) : (
                <React.Fragment />
              )}
            </Col>
          </Row>
          <Row>
            <h2>
              <Col>{appName}</Col>
            </h2>
          </Row>
          <Row>
            <Col>
              Welcome to Hauser Shade {appName} Tool. Please login to begin
              building your quote. If you don't have a login, please click the
              Register button in the upper right or below to request access.
              Access is restricted to designers and resellers.
            </Col>
          </Row>
          <Row>
            <Col>&nbsp;</Col>
          </Row>
          <Row>
            <Col md={{ span: 4, offset: 4 }}>
              <AuthLinks welcome={false} />
            </Col>
          </Row>
        </Container>
      </Jumbotron>
      <AuthDialog
        pathName={location.state ? location.state.from.pathname : "/home"}
      />
    </React.Fragment>
  );
};

Welcome.propTypes = {
  appName: PropTypes.string.isRequired,
  msg: PropTypes.string
};

export default Welcome;
