import React from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { clearForm } from "../../../actions/build";

import Dialog from "react-bootstrap-dialog";
import Button from "react-bootstrap/Button";

const ClearForm = ({ clear }) => {
  let dialog = null;

  function click() {
    dialog.show({
      body: "Are you sure you want to clear the form?",
      actions: [
        Dialog.DefaultAction(
          "Yes",
          () => {
            clear();
          },
          "btn-danger"
        ),
        Dialog.Action("No", null, "btn-default")
      ]
    });
  }

  return (
    <React.Fragment>
      <Button className="clearForm" variant="secondary" onClick={click}>
        Start New Quote
      </Button>
      <Dialog
        ref={el => {
          dialog = el;
        }}
      />
    </React.Fragment>
  );
};

ClearForm.propTypes = {
  clear: PropTypes.func.isRequired
};

const mapDispatchToProps = dispatch => ({
  clear: () => dispatch(clearForm())
});

export default connect(null, mapDispatchToProps)(ClearForm);
