const order = (
  state = {
    data: null,
    error: null,
    loading: true
  },
  action
) => {
  switch (action.type) {
    case "ORDERS_LOADING":
      return { ...state, loading: true };
    case "ORDERS_RECEIVED":
      return { ...state, data: action.data, error: null, loading: false };
    case "ORDERS_CANCEL":
      return { ...state, data: action.data, error: null };
    case "ORDERS_ERROR":
      return { ...state, data: [], error: action.error, loading: false };

    default:
      return state;
  }
};

export default order;
