import { Auth, API } from "aws-amplify";
import { loadQuote } from "./quote";

const loading = () => ({
  type: "ORDERS_LOADING"
});

const receivedOrders = data => ({
  type: "ORDERS_RECEIVED",
  data: data
});

const receivedError = msg => ({
  type: "ORDERS_ERROR",
  error: msg
});

export const requestOrders = () => async dispatch => {
  dispatch(loading);
  // Get JWT Token
  const user = await Auth.currentAuthenticatedUser();
  const token = user.signInUserSession.idToken.jwtToken;

  return API.get("APIGatewayAPI", "/order", {
    headers: { Authorization: token }
  })
    .then(function(response) {
      // sort the orders newest to oldest
      response.sort((a, b) => {
        if (b.date > a.date) return 1;
        if (a.date > b.date) return -1;
        return 0;
      });

      dispatch(receivedOrders(response));
    })
    .catch(function(error) {
      let msg = error.toString();
      if (error.response && error.response.data) {
        msg = error.response.data.error || error.message;
      }

      dispatch(receivedError(msg));
    });
};

export const editOrder = quote => async dispatch => {
  dispatch(loading);

  // Get JWT Token
  const user = await Auth.currentAuthenticatedUser();
  const token = user.signInUserSession.idToken.jwtToken;

  return API.put("APIGatewayAPI", `/order/${quote.quoteId}`, {
    headers: { Authorization: token }
  })
    .then(function(response) {
      dispatch(loadQuote(quote));
    })
    .catch(function(error) {
      let msg = error;
      if (error.response && error.response.data) {
        msg = error.response.data.error || error;
      }
      dispatch(receivedError(msg));
    });
};

const cancelOrder = data => ({
  type: "ORDERS_CANCEL",
  data: data
});

export const requestCancelOrder = (quoteId, data) => async dispatch => {
  dispatch(loading);

  // Get JWT Token
  const user = await Auth.currentAuthenticatedUser();
  const token = user.signInUserSession.idToken.jwtToken;

  return API.del("APIGatewayAPI", `/order/${quoteId}`, {
    headers: { Authorization: token }
  })
    .then(function(response) {
      dispatch(cancelOrder(data.filter(row => row.quoteId !== quoteId)));
    })
    .catch(function(error) {
      let msg = error;
      if (error.response && error.response.data) {
        msg = error.response.data.error || error;
      }
      dispatch(receivedError(msg));
    });
};
