import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Button from "react-bootstrap/Button";

import { showAuth, logout } from "../../actions/auth";

import { firstName } from "../../util/session";

const AuthLinks = ({
  welcome,
  session,
  showLogin,
  showRegistration,
  logout
}) => {
  return (
    <React.Fragment>
      {!session ? (
        <Nav>
          <Nav.Item className="mx-2">
            <Button onClick={showLogin}>Login</Button>
          </Nav.Item>
          <Nav.Item>
            <Button onClick={showRegistration}>Register</Button>
          </Nav.Item>
        </Nav>
      ) : (
          <Nav>
            {welcome ? (
              <Nav.Item className="mx-2">
                <Navbar.Text>Welcome {firstName(session)}</Navbar.Text>
              </Nav.Item>
            ) : (
                <React.Fragment />
              )}
            <Nav.Item>
              <Button variant="secondary" onClick={logout}>Logout</Button>
            </Nav.Item>
          </Nav>
        )}
    </React.Fragment>
  );
};

AuthLinks.propTypes = {
  welcome: PropTypes.bool.isRequired,
  session: PropTypes.object,
  showLogin: PropTypes.func.isRequired,
  showRegistration: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  session: state.auth.session
});

const mapDispatchToProps = dispatch => ({
  logout: () => dispatch(logout()),
  showLogin: () => dispatch(showAuth("Login")),
  showRegistration: () => dispatch(showAuth("Registration"))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AuthLinks);
