const auth = (
  state = {
    status: null,
    msg: null,
    session: null,
    user: null
  },
  action
) => {
  switch (action.type) {
    case "SHOW_AUTH":
      return {
        ...state,
        status: action.status,
        msg: action.msg
      };
    case "HIDE_AUTH":
      return {
        ...state,
        status: null,
        msg: null
      };
    case "TOGGLE_AUTH":
      return {
        ...state,
        status: state.status === "Login" ? "Registration" : "Login",
        msg: null
      };
    case "PROCESSING":
      return {
        ...state,
        status: "Processing"
      };
    case "VERIFY":
      return {
        ...state,
        status: "Verify",
        user: action.user
      };
    case "AUTHENTICATED":
      return {
        ...state,
        status: null,
        session: action.session
      };
    case "LOGGED_OUT":
      return {
        ...state,
        status: null,
        session: null
      };
    default:
      return state;
  }
};

export default auth;
