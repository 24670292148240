import update from "immutability-helper";
import { DimensionRegex } from "../views/home/orderEntry/columns";
import shadeMaterials from "../util/shadeMaterials";
import { v1 as uuidv1 } from "uuid";

const EMPTY_ROW = {
  qty: 0,
  fabricAndColor: "",
  fabric: "",
  color: "",
  motor: "",
  width: null,
  length: null,
  measurement: null,
  rollerOption: "",
  reverseRoll: "",
  clutch: "",
  chain: "",
  hem: "",
  treatment: "",
  addon: "",
  location: "",
  special: "",
  subtotal: null
};

const EMPTY_FORM_MESSAGE = "Please fill out the form";
const ERROR_STATUS_NONE = "Okay";
const DEFAULT_ROWS = 40;

function isEmptyRow(row) {
  for (var key in row) {
    if (row[key] !== EMPTY_ROW[key]) {
      return false;
    }
  }
  return true;
}

function buildMsg(rowIdx, msg) {
  return `Row ${rowIdx + 1}: ${msg}`;
}

function isValidDimension(value) {
  return DimensionRegex.test(value);
}

function validateOrder(rows, addons) {
  let lineItems = [];

  for (let rowIdx = 0; rowIdx < rows.length; rowIdx++) {
    let row = rows[rowIdx];

    // ignore empty rows
    if (isEmptyRow(row)) {
      continue;
    }

    if (row.qty === EMPTY_ROW.qty) {
      return { msg: buildMsg(rowIdx, "Enter a quantity"), isOrderValid: false };
    }

    if (isNaN(Number(row.qty))) {
      return { msg: buildMsg(rowIdx, "Invalid Quantity"), isOrderValid: false };
    }

    if (row.fabricAndColor === EMPTY_ROW.fabricAndColor) {
      return {
        msg: buildMsg(rowIdx, "Select a fabric and color"),
        isOrderValid: false
      };
    }

    if (row.fabric === EMPTY_ROW.fabric) {
      return { msg: buildMsg(rowIdx, "Invalid Fabric"), isOrderValid: false };
    }

    if (row.color === EMPTY_ROW.color) {
      return { msg: buildMsg(rowIdx, "Invalid color"), isOrderValid: false };
    }

    if (row.rollerOption === EMPTY_ROW.rollerOption) {
      return {
        msg: buildMsg(rowIdx, "Select a roller option"),
        isOrderValid: false
      };
    }

    /////////////////////////////
    // Motorization
    if (
      row.rollerOption === shadeMaterials.constants["MOTORIZED"] &&
      (row.motor === EMPTY_ROW.motor ||
        row.motor === "" ||
        row.motor === shadeMaterials.motorOptions[0].text)
    ) {
      // Need to select a motor
      return {
        msg: buildMsg(rowIdx, "Select a motor"),
        isOrderValid: false
      };
    } else if (
      row.rollerOption !== shadeMaterials.constants["MOTORIZED"] &&
      row.motor !== EMPTY_ROW.motor &&
      row.motor !== shadeMaterials.motorOptions[0].text
    ) {
      // Must select motorized if you select a motor
      return {
        msg: buildMsg(
          rowIdx,
          `Select ${shadeMaterials.constants["MOTORIZED"]} roller type or clear selected motor`
        ),
        isOrderValid: false
      };
    }

    if (row.width === EMPTY_ROW.width || row.width === "") {
      return { msg: buildMsg(rowIdx, "Enter a width"), isOrderValid: false };
    }

    if (!isValidDimension(row.width)) {
      return { msg: buildMsg(rowIdx, "Invalid width"), isOrderValid: false };
    }

    let result = shadeMaterials.maxDimensions(row, "width");
    if (!result.validDimension) {
      return {
        msg: buildMsg(
          rowIdx,
          `Width is greater than the selected fabric's maximum width of ${result.max}.`
        ),
        isOrderValid: false
      };
    }

    if (row.length === EMPTY_ROW.length || row.length === "") {
      return { msg: buildMsg(rowIdx, "Enter a length"), isOrderValid: false };
    }

    if (!isValidDimension(row.length)) {
      return { msg: buildMsg(rowIdx, "Invalid length"), isOrderValid: false };
    }

    result = shadeMaterials.maxDimensions(row, "length");
    if (!result.validDimension) {
      return {
        msg: buildMsg(
          rowIdx,
          `Length is greater than the selected fabric's maximum length of ${result.max}.`
        ),
        isOrderValid: false
      };
    }

    if (row.measurement === EMPTY_ROW.measurement) {
      return {
        msg: buildMsg(rowIdx, "Select a measurement type"),
        isOrderValid: false
      };
    }

    if (row.rollerOption === shadeMaterials.constants["BEAD_AND_REEL"]) {
      // if bead & Reel, make sure clutch and chain are specified
      if (row.clutch === EMPTY_ROW.clutch || row.clutch === "") {
        return {
          msg: buildMsg(rowIdx, "Select a clutch location"),
          isOrderValid: false
        };
      }

      if (row.chain === EMPTY_ROW.chain || row.chain === "") {
        return {
          msg: buildMsg(rowIdx, "Select a chain type"),
          isOrderValid: false
        };
      }
    }

    lineItems.push(row);
  }

  // check if we have global addons
  let haveGlobalAddons = false;
  for (const addon of Object.values(addons)) {
    for (const info of Object.values(addon)) {
      if (info.qty > 0) {
        haveGlobalAddons = true;
        break;
      }
    }
    if (haveGlobalAddons) {
      break;
    }
  }

  if (lineItems.length === 0 && haveGlobalAddons === false) {
    return { msg: buildMsg(0, EMPTY_FORM_MESSAGE), isOrderValid: false };
  }

  return {
    msg: ERROR_STATUS_NONE,
    isOrderValid: lineItems.length > 0 || haveGlobalAddons
  };
}

function setFabricAndColor(row) {
  const f = shadeMaterials.findFabric(row.fabricAndColor);
  if (!f) {
    // reset fabric and color
    row.fabric = EMPTY_ROW.fabric;
    row.color = EMPTY_ROW.color;
    return;
  }

  row.fabric = f.name;
  row.color = f.color;
}

function updateRow(action, state) {
  let rows = state.rows.slice();
  for (let i = action.fromRow; i <= action.toRow; i++) {
    let rowToUpdate = rows[i];
    let updatedRow = update(rowToUpdate, { $merge: action.updated });

    // convert types
    let qty = Number(updatedRow.qty);
    if (isNaN(qty)) qty = 0;
    updatedRow.qty = qty;

    // split off fabric and color or use an object with a renderer instead
    setFabricAndColor(updatedRow);
    rows[i] = updatedRow;
  }
  return {
    orderEntryStatus: validateOrder(rows, state.orderAddons),
    rows: rows
  };
}

function insertRow(action, state) {
  const newRow = EMPTY_ROW;

  let rows = [...state.rows];
  rows.splice(action.rowIdx, 0, newRow);

  return {
    orderEntryStatus: validateOrder(rows, state.orderAddons),
    rows: rows
  };
}

function deleteRow(action, state) {
  let rows = [...state.rows];
  rows.splice(action.rowIdx, 1);

  return {
    orderEntryStatus: validateOrder(rows, state.orderAddons),
    rows: rows
  };
}

function copyRow(action, state) {
  let rows = [...state.rows];
  const copiedRow = rows[action.rowIdx];

  rows.splice(action.rowIdx, 0, copiedRow);
  return {
    orderEntryStatus: validateOrder(rows, state.orderAddons),
    rows: rows
  };
}

function fillRows(rows) {
  if (rows.length > DEFAULT_ROWS) {
    return rows;
  } else {
    return rows.concat(
      new Array(DEFAULT_ROWS - rows.length).fill(EMPTY_ROW, 0)
    );
  }
}

const build = (
  state = {
    quoteId: uuidv1(),
    memo: "",
    sidemark: "",
    rows: new Array(DEFAULT_ROWS).fill(EMPTY_ROW, 0),
    orderAddons: {},
    orderEntryStatus: {
      isOrderValid: false,
      msg: EMPTY_FORM_MESSAGE
    },
    apiStatus: {
      processing: false,
      msg: "",
      error: false
    },
    refreshList: false
  },
  action
) => {
  let result = {};
  let rows = [];
  switch (action.type) {
    case "LOAD_QUOTE":
      rows = fillRows(action.quote.rows);
      return {
        ...state,
        memo: action.quote.memo,
        sidemark: action.quote.sidemark,
        orderAddons: action.quote.addons,
        rows: rows,
        quoteId: action.quote.quoteId,
        orderEntryStatus: validateOrder(rows, action.quote.addons)
      };
    case "UPDATE_MEMO":
      return { ...state, memo: action.value };
    case "UPDATE_SIDEMARK":
      return { ...state, sidemark: action.value };
    case "UPDATE_ORDER_ADDONS":
      let addons = JSON.parse(JSON.stringify(state.orderAddons));
      let qty = parseInt(action.qty);
      if (isNaN(qty)) {
        qty = "";
      }
      if (qty === 0) {
        if (addons[action.category]) {
          delete addons[action.category][action.addon];
          if (Object.keys(addons[action.category]).length === 0) {
            delete addons[action.category];
          }
        }
      } else {
        if (!addons[action.category]) {
          addons[action.category] = {};
        }
        if (!addons[action.category][action.addon]) {
          addons[action.category][action.addon] = {};
        }
        addons[action.category][action.addon].qty = qty;
      }
      return {
        ...state,
        orderAddons: addons,
        orderEntryStatus: validateOrder(state.rows, addons)
      };
    case "GRID_ROWS_UPDATED":
      result = updateRow(action, state);
      return {
        ...state,
        ...result
      };
    case "GRID_INSERT_ROW":
      result = insertRow(action, state);
      return {
        ...state,
        ...result
      };
    case "GRID_DELETE_ROW":
      result = deleteRow(action, state);
      return {
        ...state,
        ...result
      };
    case "GRID_COPY_ROW":
      result = copyRow(action, state);
      return {
        ...state,
        ...result
      };
    case "GRID_CLEAR":
      rows = new Array(DEFAULT_ROWS).fill(EMPTY_ROW, 0);
      let orderAddons = {};
      return {
        ...state,
        quoteId: uuidv1(),
        sidemark: "",
        rows: rows,
        orderAddons: orderAddons,
        orderEntryStatus: validateOrder(rows, orderAddons)
      };
    case "QUOTE_REQUEST":
      return {
        ...state,
        apiStatus: {
          processing: true,
          msg: "",
          error: false
        }
      };
    case "QUOTE_SUCCESS":
      return {
        ...state,
        apiStatus: {
          processing: false,
          msg: "",
          error: false
        },
        rows: fillRows(action.rows),
        orderAddons: action.addons
      };
    case "QUOTE_ERROR":
      return {
        ...state,
        apiStatus: {
          processing: false,
          msg: action.error,
          error: true
        }
      };
    case "ORDER_REQUEST":
      return {
        ...state,
        apiStatus: {
          processing: true,
          msg: "",
          error: false
        }
      };
    case "ORDER_SUCCESS":
      return {
        ...state,
        quoteId: uuidv1(),
        apiStatus: {
          processing: false,
          msg: "Order Successfully Placed",
          error: false
        }
      };
    case "ORDER_ERROR":
      return {
        ...state,
        apiStatus: {
          processing: false,
          msg: action.error,
          error: true
        }
      };

    default:
      return state;
  }
};

export default build;
