import { Auth, API } from "aws-amplify";
import { calculateTotal } from "../components/quoteTable/total";

export const updateSidemark = value => ({
  type: "UPDATE_SIDEMARK",
  value: value
});

export const updateMemo = value => ({
  type: "UPDATE_MEMO",
  value: value
});

export const updateOrderAddons = (category, addon, qty) => ({
  type: "UPDATE_ORDER_ADDONS",
  category: category,
  addon: addon,
  qty: qty
});

export const gridRowsUpdated = ({ fromRow, toRow, updated }) => ({
  type: "GRID_ROWS_UPDATED",
  fromRow: fromRow,
  toRow: toRow,
  updated: updated
});

export const insertRow = rowIdx => ({
  type: "GRID_INSERT_ROW",
  rowIdx: rowIdx
});

export const copyRow = rowIdx => ({
  type: "GRID_COPY_ROW",
  rowIdx: rowIdx
});

export const deleteRow = rowIdx => ({
  type: "GRID_DELETE_ROW",
  rowIdx: rowIdx
});

export const clearForm = () => ({
  type: "GRID_CLEAR"
});

const requestQuote = () => ({
  type: "QUOTE_REQUEST"
});

const receiveQuote = (quoteRows, addons) => ({
  type: "QUOTE_SUCCESS",
  rows: quoteRows,
  addons: addons
});

const errorQuote = msg => ({
  type: "QUOTE_ERROR",
  error: msg
});

export const getQuote = (
  quoteId,
  sidemark,
  memo,
  addons,
  rows
) => async dispatch => {
  dispatch(requestQuote());

  // Remove empty rows
  const quote = rows.filter(row => row.qty > 0);

  // Get JWT Token
  const user = await Auth.currentAuthenticatedUser();
  const token = user.signInUserSession.idToken.jwtToken;

  return API.post("APIGatewayAPI", "/quote", {
    body: {
      quoteId: quoteId,
      sidemark: sidemark,
      rows: quote,
      memo: memo,
      addons: addons
    },

    headers: { Authorization: token }
  })
    .then(function(response) {
      if (response === "" || response === null)
        throw new Error("No data returned from Quote API");

      dispatch(receiveQuote(response.rows, response.addons));
    })
    .catch(function(error) {
      let msg = error.toString();
      if (error.response && error.response.data) {
        msg = error.response.data.error || error.message;
      }

      dispatch(errorQuote(msg));
    })
    .finally(function() {
      dispatch(listRefresh());
    });
};

const requestOrder = () => ({
  type: "ORDER_REQUEST"
});

const receiveOrder = () => ({
  type: "ORDER_SUCCESS"
});

const errorOrder = msg => ({
  type: "ORDER_ERROR",
  error: msg
});

export const listRefresh = () => ({
  type: "LIST_REFRESH"
});

// Useful for mocking out API calls
// function sleep(ms) {
//   return new Promise(resolve => setTimeout(resolve, ms));
// }
// await sleep(1000);

export const placeOrder = (
  quoteId,
  sidemark,
  memo,
  addons,
  rows
) => async dispatch => {
  dispatch(requestOrder());

  // Remove empty rows
  const order = rows ? rows.filter(row => row.qty > 0) : null;

  // Get JWT Token
  const user = await Auth.currentAuthenticatedUser();
  const token = user.signInUserSession.idToken.jwtToken;

  return API.post("APIGatewayAPI", "/order", {
    body: {
      quoteId: quoteId,
      sidemark: sidemark,
      order: order,
      memo: memo,
      addons: addons,
      total: calculateTotal(order, addons)
    },
    headers: { Authorization: token }
  })
    .then(function(response) {
      dispatch(receiveOrder());
    })
    .catch(function(error) {
      let msg = error;
      if (error.response && error.response.data) {
        msg = error.response.data.error || error;
      }
      dispatch(errorOrder(msg));
    })
    .finally(function() {
      dispatch(listRefresh());
    });
};
