import PropTypes from "prop-types";

import { formatMoney } from "../../util/formatMoney";

export function calculateTotal(rows, orderAddons) {
  // Calculate the total based on the subtotal
  let rowTotal = 0.0;
  if (rows) {
    rowTotal = rows.reduce((accumulator, currentValue) => {
      if (typeof currentValue.subtotal === "string") {
        currentValue.subtotal = parseInt(currentValue.subtotal, 10);
      }

      if (isNaN(currentValue.subtotal)) return accumulator;

      return accumulator + currentValue.subtotal;
    }, 0);
  }

  let addonsTotal = 0.0;
  if (orderAddons) {
    for (const addons of Object.values(orderAddons)) {
      for (const info of Object.values(addons)) {
        if (typeof info.subtotal === "string") {
          info.subtotal = parseInt(info.subtotal, 10);
        }
        if (isNaN(info.subtotal)) continue;

        addonsTotal += info.subtotal;
      }
    }
  }

  return formatMoney(rowTotal + addonsTotal);
}

const Total = ({ rows, orderAddons }) => {
  return calculateTotal(rows, orderAddons);
};

Total.propTypes = {
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      qty: PropTypes.number,
      fabric: PropTypes.string,
      color: PropTypes.string,
      motor: PropTypes.string,
      width: PropTypes.string,
      length: PropTypes.string,
      measurement: PropTypes.string,
      rollerOption: PropTypes.string,
      reverseRoll: PropTypes.string,
      clutch: PropTypes.string,
      chain: PropTypes.string,
      hem: PropTypes.string,
      treatment: PropTypes.string,
      addon: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
      location: PropTypes.string,
      special: PropTypes.string,
      price: PropTypes.number
    })
  ),
  addons: PropTypes.object
};

export default Total;
