import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";

import { Link, NavLink } from "react-router-dom";

import AuthLinks from "../AuthLinks";

const QNavBar = ({ appName, session }) => {
  return (
    <Navbar>
      <Navbar.Brand as={Link} to="/home">
        {appName}
      </Navbar.Brand>
      <Nav className="mr-auto">
        <Nav.Link activeClassName="active" as={NavLink} to="/" exact={true}>
          Welcome
        </Nav.Link>
        <Nav.Link activeClassName="active" as={NavLink} to="/home" exact={true}>
          Build Quote
        </Nav.Link>
        <Nav.Link
          activeClassName="active"
          as={NavLink}
          to="/quotes/"
          exact={false}
        >
          Saved Quotes
        </Nav.Link>
        <Nav.Link
          activeClassName="active"
          as={NavLink}
          to="/orders/"
          exact={false}
        >
          Orders
        </Nav.Link>
      </Nav>
      <AuthLinks welcome />
    </Navbar>
  );
};

QNavBar.propTypes = {
  appName: PropTypes.string.isRequired,
  session: PropTypes.object
};

const mapStateToProps = state => ({
  session: state.auth.session
});

export default connect(mapStateToProps, null)(QNavBar);
