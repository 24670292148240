import { combineReducers } from "redux";
import auth from "./authReducer";
import build from "./buildReducer";
import quote from "./quoteReducer";
import order from "./orderReducer";

export default combineReducers({
  auth,
  build,
  quote,
  order
});
