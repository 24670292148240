import React, { useState } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { getQuote, placeOrder } from "../../../actions/build";

import QuoteTable from "../../../components/quoteTable/table";

import { Alert, Modal, Button, Form } from "react-bootstrap";

import "./quote.css";

const Quote = ({
  quoteId,
  sidemark,
  memo,
  addons,
  rows,
  getQuote,
  apiStatus,
  placeOrder,
  isOrderValid
}) => {
  const [show, setShow] = useState(false);
  function handleClose() {
    setShow(false);
  }

  function handleQuoteClick() {
    setShow(true);
    getQuote(quoteId, sidemark, memo, addons, rows);
  }

  function handleOrderClick() {
    placeOrder(quoteId, sidemark, memo, addons, rows);
  }

  const showAlert = apiStatus.error || apiStatus.msg !== "";

  return (
    <React.Fragment>
      <Button
        style={{ marginTop: -6 }}
        className="float-right"
        onClick={handleQuoteClick}
        disabled={!isOrderValid}
      >
        Get Quote
      </Button>
      {show ? (
        <Modal
          size={apiStatus.processing ? "sm" : "lg"}
          show={show}
          dialogClassName={!apiStatus.processing ? "modal-90w" : ""}
          onHide={handleClose}
          keyboard={false}
        >
          {apiStatus.processing ? (
            <center>
              <img src="processing.gif" alt="" />
            </center>
          ) : (
            <React.Fragment>
              <Modal.Header>
                <Modal.Title id="contained-modal-title-lg">
                  Quote {sidemark ? `- ${sidemark}` : ""}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {showAlert ? (
                  <Alert variant={apiStatus.error ? "danger" : "success"}>
                    {apiStatus.error ? (
                      <p>
                        Something went wrong. Please contact Hauser Shade and
                        let us know about the error below. Thanks!
                      </p>
                    ) : (
                      <React.Fragment />
                    )}
                    <p>{apiStatus.msg}</p>
                  </Alert>
                ) : (
                  <React.Fragment />
                )}
                <QuoteTable rows={rows} addons={addons} />
                {memo ? (
                  <Form.Group controlId="quote.memo">
                    <Form.Label>Memo / Instructions</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows="3"
                      readOnly
                      value={memo}
                    />
                  </Form.Group>
                ) : (
                  <React.Fragment />
                )}
              </Modal.Body>
              {!showAlert ? (
                <Modal.Footer>
                  <Button onClick={handleClose}>Cancel</Button>
                  <Button onClick={handleOrderClick}>Place Order</Button>
                </Modal.Footer>
              ) : (
                <Modal.Footer>
                  <Button onClick={handleClose}>Close</Button>
                </Modal.Footer>
              )}
            </React.Fragment>
          )}
        </Modal>
      ) : (
        <React.Fragment />
      )}
    </React.Fragment>
  );
};

Quote.propTypes = {
  quoteId: PropTypes.string,
  sidemark: PropTypes.string,
  memo: PropTypes.string,
  addons: PropTypes.object.isRequired,
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      qty: PropTypes.number,
      fabric: PropTypes.string,
      color: PropTypes.string,
      motor: PropTypes.string,
      width: PropTypes.string,
      length: PropTypes.string,
      measurement: PropTypes.string,
      rollerOption: PropTypes.string,
      reverseRoll: PropTypes.string,
      clutch: PropTypes.string,
      chain: PropTypes.string,
      hem: PropTypes.string,
      treatment: PropTypes.string,
      addon: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
      location: PropTypes.string,
      special: PropTypes.string,
      price: PropTypes.number
    })
  ).isRequired,
  apiStatus: PropTypes.shape({
    processing: PropTypes.bool.isRequired,
    msg: PropTypes.string,
    error: PropTypes.bool.isRequired
  }),
  getQuote: PropTypes.func.isRequired,
  placeOrder: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  quoteId: state.build.quoteId,
  sidemark: state.build.sidemark,
  memo: state.build.memo,
  addons: state.build.orderAddons,
  rows: state.build.rows,
  isOrderValid: state.build.orderEntryStatus.isOrderValid,
  apiStatus: state.build.apiStatus
});

const mapDispatchToProps = dispatch => ({
  getQuote: (quoteId, sidemark, memo, addons, rows) =>
    dispatch(getQuote(quoteId, sidemark, memo, addons, rows)),
  placeOrder: (quoteId, sidemark, memo, addons, rows) =>
    dispatch(placeOrder(quoteId, sidemark, memo, addons, rows))
});

export default connect(mapStateToProps, mapDispatchToProps)(Quote);
