import React from "react";
import PropTypes from "prop-types";
import { Menu } from "react-data-grid-addons";
import "./gridContextMenu.css";

const { ContextMenu, SubMenu, MenuItem } = Menu;

// Create the context menu.
const GridContextMenu = ({
  id,
  rowIdx,
  onRowDelete,
  onRowInsert,
  onRowCopy
}) => {
  function handleRowDelete(e, { rowIdx }) {
    onRowDelete(rowIdx);
  }

  function handleRowInsertAbove(e, { rowIdx }) {
    onRowInsert(rowIdx);
  }

  function handleRowInsertBelow(e, { rowIdx }) {
    onRowInsert(rowIdx + 1);
  }

  function handleRowCopy(e, { rowIdx }) {
    onRowCopy(rowIdx);
  }

  return (
    <ContextMenu id={id}>
      <MenuItem data={{ rowIdx }} onClick={handleRowCopy}>
        Copy Row
      </MenuItem>
      <MenuItem data={{ rowIdx }} onClick={handleRowDelete}>
        Delete Row
      </MenuItem>
      <SubMenu title="Insert Row">
        <MenuItem data={{ rowIdx }} onClick={handleRowInsertAbove}>
          Above
        </MenuItem>
        <MenuItem data={{ rowIdx }} onClick={handleRowInsertBelow}>
          Below
        </MenuItem>
      </SubMenu>
    </ContextMenu>
  );
};
GridContextMenu.propTypes = {
  onRowDelete: PropTypes.func.isRequired,
  onRowInsert: PropTypes.func.isRequired,
  onRowCopy: PropTypes.func.isRequired,
  rowIdx: PropTypes.number,
  id: PropTypes.string
};

export default GridContextMenu;
