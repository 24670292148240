import { Auth, API } from "aws-amplify";
import { listRefresh } from "./build";
import { calculateTotal } from "../components/quoteTable/total";
import history from "../history";

export const loadQuote = quote => async dispatch => {
  dispatch({
    type: "LOAD_QUOTE",
    quote: quote
  });
  history.push("/home");
};

const loading = () => ({
  type: "QUOTES_LOADING"
});

const receivedQuotes = data => ({
  type: "QUOTES_RECEIVED",
  data: data
});

const receivedError = error => {
  let msg = error.toString();
  if (error.response && error.response.data) {
    msg = error.response.data.error || error.message;
  }

  return {
    type: "QUOTES_ERROR",
    error: msg
  };
};

export const requestQuotes = () => async dispatch => {
  dispatch(loading);
  // Get JWT Token
  const user = await Auth.currentAuthenticatedUser();
  const token = user.signInUserSession.idToken.jwtToken;

  return API.get("APIGatewayAPI", "/quote", {
    headers: { Authorization: token }
  })
    .then(function(response) {
      // sort the orders newest to oldest
      response.sort((a, b) => {
        if (b.date > a.date) return 1;
        if (a.date > b.date) return -1;
        return 0;
      });

      dispatch(receivedQuotes(response));
    })
    .catch(function(error) {
      dispatch(receivedError(error));
    });
};

export const editQuote = quote => async dispatch => {
  dispatch(loadQuote(quote));
};

const requestDeleteQuote = () => ({
  type: "DELETE_QUOTE_REQUEST"
});

const completedDeleteQuote = () => ({
  type: "QUOTE_DELETE_COMPLETED"
});

const errorDeleteQuote = msg => ({
  type: "QUOTE_DELETE_ERROR",
  error: msg
});

export const deleteQuote = quoteId => async dispatch => {
  dispatch(requestDeleteQuote());

  // Get JWT Token
  const user = await Auth.currentAuthenticatedUser();
  const token = user.signInUserSession.idToken.jwtToken;

  return API.del("APIGatewayAPI", `/quote/${quoteId}`, {
    headers: { Authorization: token }
  })
    .then(function(response) {
      dispatch(completedDeleteQuote());
    })
    .catch(function(error) {
      let msg = error;
      if (error.response && error.response.data) {
        msg = error.response.data.error || error;
      }
      dispatch(errorDeleteQuote(msg));
    })
    .finally(function() {
      dispatch(listRefresh());
    });
};

const requestOrder = () => ({
  type: "ORDER_REQUEST"
});

const receiveOrder = () => ({
  type: "ORDER_SUCCESS"
});

const errorOrder = msg => ({
  type: "ORDER_ERROR",
  error: msg
});

export const placeOrder = (
  quoteId,
  sidemark,
  memo,
  addons,
  rows
) => async dispatch => {
  dispatch(requestOrder());

  // Remove empty rows
  const order = rows ? rows.filter(row => row.qty > 0) : null;

  // Get JWT Token
  const user = await Auth.currentAuthenticatedUser();
  const token = user.signInUserSession.idToken.jwtToken;

  return API.post("APIGatewayAPI", "/order", {
    body: {
      quoteId: quoteId,
      sidemark: sidemark,
      order: order,
      memo: memo,
      addons: addons,
      total: calculateTotal(order, addons)
    },
    headers: { Authorization: token }
  })
    .then(function(response) {
      dispatch(receiveOrder());
    })
    .catch(function(error) {
      let msg = error;
      if (error.response && error.response.data) {
        msg = error.response.data.error || error;
      }
      dispatch(errorOrder(msg));
    })
    .finally(function() {
      dispatch(listRefresh());
    });
};
